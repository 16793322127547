<template>
	<div>
		<table class="table liaTableInfo" border="0" style="border-collapse: collapse;">
			<thead>
				<tr class="noInput">
					<th style="width: 31%;">项目</th>
					<th style="width: 23%;" v-for="(item,index) in year" :key="index - 1">
						{{item}}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>应收账款</td>
					<td v-for="(item,index) in liaTableInfo.receivables" :key="index + 1">
						{{judgingCondition('receivables',index)?method.formateNum(liaTableInfo.receivables[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>存货</td>
					<td v-for="(item,index) in liaTableInfo.inventory" :key="index + 2">
						{{judgingCondition('inventory',index)?method.formateNum(liaTableInfo.inventory[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>流动资产合计</td>
					<td v-for="(item,index) in liaTableInfo.totalCurrentAssets" :key="index + 3">
						{{judgingCondition('totalCurrentAssets',index)?method.formateNum(liaTableInfo.totalCurrentAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>无形资产（不含土地使用权、矿业权、水域使用权）</td>
					<td v-for="(item,index) in liaTableInfo.netWorthCombination" :key="index + 10">
						{{judgingCondition('netWorthCombination',index)?method.formateNum(liaTableInfo.netWorthCombination[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>非流动资产合计</td>
					<td v-for="(item,index) in liaTableInfo.totalNonCurrentAssets" :key="index + 4">
						{{judgingCondition('totalNonCurrentAssets',index)?method.formateNum(liaTableInfo.totalNonCurrentAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>资产总计</td>
					<td v-for="(item,index) in liaTableInfo.totalAssets" :key="index + 5">
						{{judgingCondition('totalAssets',index)?method.formateNum(liaTableInfo.totalAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>流动负债合计</td>
					<td v-for="(item,index) in liaTableInfo.totalNonLiabilities" :key="index + 6">
						{{judgingCondition('totalNonLiabilities',index)?method.formateNum(liaTableInfo.totalNonLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>非流动负债合计</td>
					<td v-for="(item,index) in liaTableInfo.totalFixedLiabilities" :key="index + 7">
						{{judgingCondition('totalFixedLiabilities',index)?method.formateNum(liaTableInfo.totalFixedLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>负债合计</td>
					<td v-for="(item,index) in liaTableInfo.totalLiabilities" :key="index + 8">
						{{judgingCondition('totalLiabilities',index)?method.formateNum(liaTableInfo.totalLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>所有者权益(或股东权益)合计</td>
					<td v-for="(item,index) in liaTableInfo.totalOwnershipInterest" :key="index + 9">
						{{judgingCondition('totalOwnershipInterest',index)?method.formateNum(liaTableInfo.totalOwnershipInterest[index],2):'-'}}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	export default ({
		props: [
			'liaTableInfo', 'year', 'versions'
		],
		data() {
			return {
				dateFormate: "12月31日"
			}
		},
		mounted() {
			if (this.versions == 1) {
				this.dateFormate = "";
			} else if (this.versions == 'trace') {
				this.dateFormate = "年12月31日"
			}
		},
		methods: {
			judgingCondition: function(key, i, index) {
				if (this.liaTableInfo[key][i] === 0) {
					return true
				} else if (this.liaTableInfo[key][i] != 0 && this.liaTableInfo[key][i] != '-' && this.liaTableInfo[key][i]) {
					return true
				} else {
					return false
				}
			},

		}
	})
</script>
<style scoped lang="less">
	.titleBox {
		margin: 130px 0 0 0;
		padding: 0px 110px;
		display: flex;
		justify-content: space-between;
	}

	.titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.titleBox .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		margin: 28px auto 0;
		border: 1px solid #1416F4;
	}

	table>tbody>tr>td:first-of-type {
		padding-left: 40px;
		padding-right: 10px;
		text-align: left;
		font-weight: bold;
	}

	table>tbody>tr>td.trTwo {
		padding-left: 57px;
	}

	.title {
		margin: 0 !important;
	}

	table tr td,
	table tr th {
		height: 50px !important;
		border: 1px solid #8A8BFA;
		height: auto;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table tr th {
		border-bottom-color: #8A8BFA;
		background: #1416F4;
		color: #fff;
	}

	table>tbody>tr {
		font-size: 16px;
		color: #282828;
	}

	table>thead>tr>th {
		font-size: 18px;
		color: #fff;
		line-height: 44px;
	}
</style>