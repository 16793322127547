<template>
	<div :class="['financeTabulation','forecast',forecastData.years.length==3?'threeYears':'twoYears']">
		<p class="unit">单位：元</p>

		<ul class="forms" v-for="(yearData,f) in bisectionForecast" :key="'forecast'+f">
			<i></i>
			<li>
				<span>项目</span>
				<span v-for="(item,i) in yearData.years">{{item}}<span v-if="i==0&&f==0" class="reRed">*</span></span>
			</li>
			<li>
				<span>{{text}}</span>
				<span v-for="(item,i) in yearData[key].productSum.prediction">
					<input type="number" v-model="item.value" @change="change(f,i)"
						@blur="((ev)=>{displayThousandths(ev,item.value,yearData[key],i)})" @focus="focus($event)"
						@wheel="method.disableWheel($event)" :disabled="yearData[forecastInfo]&&i==0?true:false">
					<span class="showInput"
						v-if="yearData[forecastInfo]&&i==0">{{item.value?method.formateNum(item.value,2):''}}</span>
					<span class="showInput" v-else @click="inputFocus($event)">
						{{item.value?method.formateNum(item.value,2):''}}
					</span>
				</span>
			</li>

			<i></i>
		</ul>
		
		<div class="basisDescription">
			<label for="">预测依据描述</label>
			<div class="forecastingBasis">
				<textarea cols="20" rows="4" maxlength="500" v-model="predict"
					placeholder="请根据公司当前业务类型、经营状况和未来发展规划，结合公司目前已签约订单（含未执行完部分）、已签订的意向/框架协议、正在接洽中的合作伙伴的情况，对未来数据进行预测，并对预测依据进行简要描述。"></textarea>
				<p class="storyDescLength">{{predict?predict.length:0}}/500</p>
			</div>
		</div>

	</div>
</template>

<script>
	import common from '@/views/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: String,
			queryMethod: String, //查询接口的方法
			saveMethod: String, //保存接口的方法
			jumpAddress: String, //保存之后的跳转地址
			dataInfo: Object,
			tableType: {
				type: [String, Number] // 0: 营业收入预测   1：净利润
			}


		},
		data() {
			return {
				forecastData: this.dataInfo,
				isSave: false,
				text: this.tableType == 1 ? '净利润' : '营业收入',
				key: this.tableType == 1 ? 'netProfit' : 'income',
				forecastInfo: this.tableType == 1 ? 'baseInfoNetProfit' : 'baseInfoIncome',
				bisectionForecast: [],
				predict: '',
				splicedData: {}


			}
		},
		watch: {
			dataInfo: {
				handler: function(newValue, oldValue) {
					this.$set(this, 'forecastData', newValue)

					this.$set(this, 'splicedData', this.convertData(newValue))
					this.$set(this, 'predict', newValue[this.key].predict)
					const data = []

					var num = newValue.years.length > 5 ? 2 : 1
					var bisectingData = this.method.paginateData(newValue.years, num)

					bisectingData.map((item, i) => {

						data.push({
							[this.key]: {
								productSum: {
									businessName: 'sum',
									prediction: (i == 0 ? this.convertData(newValue[this.key].productSum.prediction.slice(0,
										bisectingData[0].length)) : this.convertData(newValue[this.key].productSum.prediction
										.slice(bisectingData[0].length)))
								}
							},
							years: this.convertData(item)
						})

					})

					console.log('分行后的数据---', data)

					this.$set(this, 'bisectionForecast', data)


					console.log('新数据--', newValue)


				},
				immediate: true, // 在初始加载时也会触发监听函数
				deep: true
			}

		},
		mounted() {
			this.pageModify('false');

		},
		methods: {
			...mapMutations(['pageModify']),
			convertData(obj) {
				const newData = JSON.parse(JSON.stringify(obj));;
				return newData
			},
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")
			},
			inputFocus(e) {
				this.isSave = false
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'inline-block';
				e.currentTarget.previousSibling.focus();
			},
			change(f, j) {
				this.pageModify('true');
				let forecast = this.bisectionForecast[f][this.key]

				if (f == 1) {
					if (j == 0) {
						if (Number(forecast.productSum.prediction[j].value) < Number(this.bisectionForecast[0][this.key].productSum
								.prediction[this.bisectionForecast[0].years.length - 1].value)) {
							this.showDialogBar(true, 'notEmpty-info', this.bisectionForecast[f].years[j] + this.text +
								'预测数据较前一年度呈下降趋势，请核实是否符合公司实际经营情况。');
						}

					} else {
						if (Number(forecast.productSum.prediction[j].value) < Number(forecast.productSum.prediction[j - 1].value)) {
							this.showDialogBar(true, 'notEmpty-info', this.bisectionForecast[f].years[j] + this.text +
								'预测数据较前一年度呈下降趋势，请核实是否符合公司实际经营情况。');
						}
					}

				} else {
					if (j != 0 && Number(forecast.productSum.prediction[j].value) < Number(forecast.productSum.prediction[j - 1]
							.value)) {
						this.showDialogBar(true, 'notEmpty-info', this.bisectionForecast[f].years[j] + this.text +
							'预测数据较前一年度呈下降趋势，请核实是否符合公司实际经营情况。');
					}
				}


			},
			displayThousandths: function(e, val, obj, i) {
				this.isSave = false
				e.currentTarget.setAttribute("placeholder", "请输入")
				e.currentTarget.nextSibling.style.display = 'block';
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数


				if (val.length < 20) {

				} else {
					obj.productSum.prediction[i].value = ''

					this.$Message.error({
						background: true,
						content: '请输入合理数据'
					});
					return false;
				}


			},
			save() {
				var this_ = this
				var shouldBreak = false; // 标志变量

				if (this.bisectionForecast.length > 1) {
					var productSum = this.bisectionForecast[0][this.key].productSum.prediction.concat(this.bisectionForecast[1][
						this.key
					].productSum.prediction)

				} else {
					var productSum = this.bisectionForecast[0][this.key].productSum.prediction
				}


				this.$set(this.splicedData[this.key].productSum, 'prediction', productSum)


				console.log(this.splicedData)


				if (this.tableType != 1) {
					for (var j = 0; j < this.splicedData[this.key].productSum.prediction.length && !shouldBreak; j++) {
						if (!this.splicedData[this.key].productSum.prediction[0].value) {
							this.showDialogBar(true, 'notEmpty-info', this.splicedData.years[j] + this.text + '为必填');
							shouldBreak = true;
							break;
						} else if (this.splicedData[this.key].productSum.prediction[0].value && this.splicedData[this.key]
							.productSum.prediction[0].value < 0) {
							this.showDialogBar(true, 'notEmpty-info', this.splicedData.years[j] + this.text + '必须大于零');
							shouldBreak = true;
							break;
						} else if (this.splicedData[this.key].productSum.prediction[j].value && this.splicedData[this.key]
							.productSum.prediction[j].value < 0) {
							this.showDialogBar(true, 'notEmpty-info', this.splicedData.years[j] + this.text + '必须大于零');
							shouldBreak = true;
							break;
						}
					}

					if (shouldBreak) {
						return false
					}

				} else {
					if (!this.splicedData[this.key].productSum.prediction[0].value) {
						this.showDialogBar(true, 'notEmpty-info', this.splicedData.years[0] + this.text + '为必填');
						return false
					}

				}




				console.log('保存')



				this.saveFinancialData()

			},
			saveFinancialData() {
				var this_ = this
				if (!this.isSave) {
					this.isSave = true

					var incomeJson = {
						productSum: this.splicedData[this.key].productSum,
						years: this.splicedData.years,
						predict: this.predict
					}

					var parame = {
						stage: '',
						versions: '',
						reportType: this.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						id: this.splicedData.id ? this.splicedData.id : '',
					}

					if (this.tableType == 1) {
						parame.netProfitJson = JSON.stringify(incomeJson)
					} else {
						parame.incomeJson = JSON.stringify(incomeJson)
					}


					console.log(incomeJson)

					this.$post(this.saveMethod, qs.stringify(parame))
						.then(res => {
							setTimeout(function() {
								this_.$router.push({
									name: this_.jumpAddress,
									query: {
										reportId: JSON.parse(res.data.content).reportId
									}
								})
							}, 500)

						})

				}
			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
			}






		}


	}
</script>

<style>
</style>