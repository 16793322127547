const timeStamp = new Date().getTime()
module.exports = {
	publicPath: './', //当路由模式为history时 publicPath：/   不是history时需设置为 ./  (路由模式为history时本地看dist index.html空白没关系放到线上就可以看了)
	outputDir: 'dist',
	runtimeCompiler: true, //关键点在这  
	devServer: {
		// 设置主机地址
		host: 'localhost',
		// 设置默认端口
		port: 8080,
		// 设置代理
		proxy: {
			'/api': {
				// 目标 API 地址
				target: 'https://www.flcccc.com/',
				// target:'http://192.168.12.120:8089/',
				// 如果要代理 websockets
				ws: true,
				// 将主机标头的原点更改为目标URL
				changeOrigin: true,
				pathRewrite: {
					'^/api': '/'
				}
			}
		}
	},
	// 将构建好的文件输出到哪里
	outputDir: 'dist',
	configureWebpack: { // 重点
		// 输出重构 打包编译后的js文件名称,添加时间戳.
		output: {
			filename: `js/[name].${timeStamp}.js`,
			chunkFilename: `js/chunk.[id].${timeStamp}.js`,
		}
	},
	css: {
		// loaderOptions: {
		// 	css: {},
		// 	postcss: {
		// 		plugins: [
		// 			require('postcss-px2rem')({
		// 				remUnit: 37.5
		// 			})
		// 		]
		// 	}
		// }
	}

}
