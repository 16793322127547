<template>
	<div>
		<table class="table" border="0" style="border-collapse: collapse;">
			<thead>
				<tr class="noInput">
					<th style="width: 31%;">项目</th>
					<th style="width: 23%;" v-for="(item,index) in year" :key="index - 1">
						{{item}}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>营业收入</td>
					<td v-for="(item,index) in profitTableInfo.income" :key="index + 1">
						{{judgingCondition('income',index)?method.formateNum(profitTableInfo.income[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>营业成本</td>
					<td v-for="(item,index) in profitTableInfo.cost" :key="index + 1">
						{{judgingCondition('cost',index)?method.formateNum(profitTableInfo.cost[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>利息费用</td>
					<td v-for="(item,index) in profitTableInfo.interestCost" :key="index + 1">
						{{judgingCondition('interestCost',index)?method.formateNum(profitTableInfo.interestCost[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>利润总额</td>
					<td v-for="(item,index) in profitTableInfo.totalProfit" :key="index + 1">
						{{judgingCondition('totalProfit',index)?method.formateNum(profitTableInfo.totalProfit[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>毛利润</td>
					<td v-for="(item,index) in profitTableInfo.grossProfit" :key="index + 1">
						{{judgingCondition('grossProfit',index)?method.formateNum(profitTableInfo.grossProfit[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>所得税</td>
					<td v-for="(item,index) in profitTableInfo.incomeTax" :key="index + 1">
						{{judgingCondition('incomeTax',index)?method.formateNum(profitTableInfo.incomeTax[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td>净利润</td>
					<td v-for="(item,index) in profitTableInfo.netProfit" :key="index + 1">
						{{judgingCondition('netProfit',index)?method.formateNum(profitTableInfo.netProfit[index],2):'-'}}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	export default ({
		props: [
			'profitTableInfo', 'year', 'versions'
		],
		data() {
			return {
				dateFormate: "12月31日"
			}
		},
		mounted() {
			if (this.versions == 1) {
				this.dateFormate = "";
			} else if (this.versions == 'trace') {
				this.dateFormate = "年12月31日"
			}
		},
		methods: {
			judgingCondition: function(key, i, index) {
				if (this.profitTableInfo[key][i] === 0) {
					return true
				} else if (this.profitTableInfo[key][i] != 0 && this.profitTableInfo[key][i] != '-' && this.profitTableInfo[
						key][i]) {
					return true
				} else {
					return false
				}
			},

		}
	})
</script>
<style scoped>
	.titleBox {
		margin: 130px 0 0 0;
		padding: 0px 110px;
		display: flex;
		justify-content: space-between;
	}

	.titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.titleBox .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		margin: 28px auto 0;
		border: 1px solid #1416F4;
	}

	table>tbody>tr>td:first-of-type {
		padding-left: 124px;
		text-align: left;
		font-weight: bold;
	}

	.title {
		margin: 0 !important;
	}

	table tr td,
	table tr th {
		height: 50px !important;
		border: 1px solid #8A8BFA;
		height: auto;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table tr th {
		border-bottom-color: #8A8BFA;
		background: #1416F4;
		color: #fff;
	}

	table>tbody>tr {
		font-size: 16px;
		color: #282828;
	}

	table>thead>tr>th {
		font-size: 18px;
		color: #fff;
		line-height: 44px;
	}
</style>