<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="newViewPage"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav :type="type" category='资产负债表' />
				</div>
				<div class="newViewInfo">
					<div class="fillinMain">
						<img class="leftTop" :src="require('@/assets/img/dataValutaion/leftTop.png')" />
						<img class="leftBottom" :src="require('@/assets/img/dataValutaion/leftBottom.png')" />
						<img class="rightTop" :src="require('@/assets/img/dataValutaion/rightTop.png')" />
						<img class="rightBottom" :src="require('@/assets/img/dataValutaion/rightBottom.png')" />
						<div class="fillinContent">
							<p class="examineTitle">资产负债表</p>
							<div class="specialtyFinance examine">
								<p class="unit">单位：元</p>
								<div>
									<ul class="yeaAccount">
										<i></i>
										<li>
											<span>项目</span>
											<span v-for="(item,i) in yearList">
												{{item}}
											</span>
										</li>
										<i></i>
									</ul>

									<div class="tabulation">
										<ul class="professionalForm">
											<li>
												<span class="trOne">流动资产：</span>
												<span v-for="(item,i) in yearList"></span>
											</li>
											<li>
												<span class="trTwo">货币资金</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.monetaryCapital[i]"
														@blur="((ev)=>{blur(ev,liabilities,'monetaryCapital',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('monetaryCapital',i)?method.formateNum(liabilities.monetaryCapital[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">交易性金融资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.tradingAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'tradingAssets',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('tradingAssets',i)?method.formateNum(liabilities.tradingAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">衍生金融资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.derivativeAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'derivativeAssets',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('derivativeAssets',i)?method.formateNum(liabilities.derivativeAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">应收票据</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.billReceivable[i]"
														@blur="((ev)=>{blur(ev,liabilities,'billReceivable',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('billReceivable',i)?method.formateNum(liabilities.billReceivable[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">应收账款</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.receivables[i]"
														@blur="((ev)=>{blur(ev,liabilities,'receivables',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('receivables',i)?method.formateNum(liabilities.receivables[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">应收账款融资</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.accountReceivable[i]"
														@blur="((ev)=>{blur(ev,liabilities,'accountReceivable',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('accountReceivable',i)?method.formateNum(liabilities.accountReceivable[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">预付款项</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.prepayment[i]"
														@blur="((ev)=>{blur(ev,liabilities,'prepayment',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('prepayment',i)?method.formateNum(liabilities.prepayment[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">其他应收款</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherReceivables[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherReceivables',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherReceivables',i)?method.formateNum(liabilities.otherReceivables[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">存货</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.inventory[i]"
														@blur="((ev)=>{blur(ev,liabilities,'inventory',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('inventory',i)?method.formateNum(liabilities.inventory[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">合同资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.contractualAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'contractualAssets',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('contractualAssets',i)?method.formateNum(liabilities.contractualAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">持有待售资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.saleForAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'saleForAssets',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('saleForAssets',i)?method.formateNum(liabilities.saleForAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">一年内到期的非流动资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.illiquidAssetsOne[i]"
														@blur="((ev)=>{blur(ev,liabilities,'illiquidAssetsOne',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('illiquidAssetsOne',i)?method.formateNum(liabilities.illiquidAssetsOne[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">
													其他流动资产
													<span class="desc-container">
														<span></span>
														<div class="desc-main">
															<span></span>
															<p>此处应填写未在本会计报表内统计的所有流动资产科目汇总金额。</p>
														</div>
													</span>
												</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherCurrentAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherCurrentAssets',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherCurrentAssets',i)?method.formateNum(liabilities.otherCurrentAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">流动资产合计</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.totalCurrentAssets[i]" disabled>
													<span class="showInput">
														{{judgingCondition('totalCurrentAssets',i)?method.formateNum(liabilities.totalCurrentAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">非流动资产：</span>
												<span v-for="(item,i) in yearList"></span>
											</li>
											<li>
												<span class="trTwo">债权投资</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.debtInvestment[i]"
														@blur="((ev)=>{blur(ev,liabilities,'debtInvestment',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('debtInvestment',i)?method.formateNum(liabilities.debtInvestment[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">其他债权投资</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherDebtInvestment[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherDebtInvestment',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherDebtInvestment',i)?method.formateNum(liabilities.otherDebtInvestment[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">长期应收款</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.longAeceivables[i]"
														@blur="((ev)=>{blur(ev,liabilities,'longAeceivables',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('longAeceivables',i)?method.formateNum(liabilities.longAeceivables[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">长期股权投资</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.longInvestment[i]"
														@blur="((ev)=>{blur(ev,liabilities,'longInvestment',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('longInvestment',i)?method.formateNum(liabilities.longInvestment[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">其他权益工具投资</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherEquityInvestment[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherEquityInvestment',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherEquityInvestment',i)?method.formateNum(liabilities.otherEquityInvestment[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">其他非流动金融资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherIlliquidAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherIlliquidAssets',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherIlliquidAssets',i)?method.formateNum(liabilities.otherIlliquidAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">投资性房地产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.investmentProperty[i]"
														@blur="((ev)=>{blur(ev,liabilities,'investmentProperty',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('investmentProperty',i)?method.formateNum(liabilities.investmentProperty[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">
													固定资产
													<span class="desc-container">
														<span></span>
														<div class="desc-main">
															<span></span>
															<p>此处指固定资产期末净值。</p>
														</div>
													</span>
												</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.fixedAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'fixedAssets',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('fixedAssets',i)?method.formateNum(liabilities.fixedAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">在建工程</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.constructionProjects[i]"
														@blur="((ev)=>{blur(ev,liabilities,'constructionProjects',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('constructionProjects',i)?method.formateNum(liabilities.constructionProjects[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">生产性生物资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.productiveAsset[i]"
														@blur="((ev)=>{blur(ev,liabilities,'productiveAsset',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('productiveAsset',i)?method.formateNum(liabilities.productiveAsset[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">油气资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.oilAndGasAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'oilAndGasAssets',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('oilAndGasAssets',i)?method.formateNum(liabilities.oilAndGasAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">使用权资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.rightAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'rightAssets',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('rightAssets',i)?method.formateNum(liabilities.rightAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">无形资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.netWorth[i]"
														@blur="((ev)=>{blur(ev,liabilities,'netWorth',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('netWorth',i)?method.formateNum(liabilities.netWorth[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">开发支出</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.developExpense[i]"
														@blur="((ev)=>{blur(ev,liabilities,'developExpense',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('developExpense',i)?method.formateNum(liabilities.developExpense[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">商誉</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.goodwill[i]"
														@blur="((ev)=>{blur(ev,liabilities,'goodwill',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('goodwill',i)?method.formateNum(liabilities.goodwill[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">长期待摊费用</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.longExpenses[i]"
														@blur="((ev)=>{blur(ev,liabilities,'longExpenses',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('longExpenses',i)?method.formateNum(liabilities.longExpenses[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">递延所得税资产</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.deferredAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'deferredAssets',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('deferredAssets',i)?method.formateNum(liabilities.deferredAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">
													其他非流动资产
													<span class="desc-container">
														<span></span>
														<div class="desc-main">
															<span></span>
															<p>此处应填写未在本会计报表内统计的所有非流动资产科目汇总金额。</p>
														</div>
													</span>
												</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherNonCurrentAssets[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherNonCurrentAssets',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherNonCurrentAssets',i)?method.formateNum(liabilities.otherNonCurrentAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">非流动资产合计</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.totalNonCurrentAssets[i]" disabled>
													<span class="showInput">
														{{judgingCondition('totalNonCurrentAssets',i)?method.formateNum(liabilities.totalNonCurrentAssets[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">资产总计</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.totalAssets[i]" disabled>
													<span class="showInput">
														{{judgingCondition('totalAssets',i)?method.formateNum(liabilities.totalAssets[i],2):'-'}}
													</span>
												</span>
											</li>
										</ul>


										<ul class="professionalForm">
											<li>
												<span class="trOne">流动负债：</span>
												<span v-for="(item,i) in yearList"></span>
											</li>
											<li>
												<span class="trTwo">短期借款</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.shortLoan[i]"
														@blur="((ev)=>{blur(ev,liabilities,'shortLoan',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('shortLoan',i)?method.formateNum(liabilities.shortLoan[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">交易性金融负债</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.transactionLiabilities[i]"
														@blur="((ev)=>{blur(ev,liabilities,'transactionLiabilities',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('transactionLiabilities',i)?method.formateNum(liabilities.transactionLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">衍生金融负债</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.derivativeLiabilities[i]"
														@blur="((ev)=>{blur(ev,liabilities,'derivativeLiabilities',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('derivativeLiabilities',i)?method.formateNum(liabilities.derivativeLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">应付票据</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.notesPayable[i]"
														@blur="((ev)=>{blur(ev,liabilities,'notesPayable',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('notesPayable',i)?method.formateNum(liabilities.notesPayable[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">应付账款</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.accountsPayable[i]"
														@blur="((ev)=>{blur(ev,liabilities,'accountsPayable',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('accountsPayable',i)?method.formateNum(liabilities.accountsPayable[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">预收款项</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.advanceReceipt[i]"
														@blur="((ev)=>{blur(ev,liabilities,'advanceReceipt',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('advanceReceipt',i)?method.formateNum(liabilities.advanceReceipt[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">合同负债</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.contractualLiabilities[i]"
														@blur="((ev)=>{blur(ev,liabilities,'contractualLiabilities',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('contractualLiabilities',i)?method.formateNum(liabilities.contractualLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">应付职工薪酬</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.payrollPayable[i]"
														@blur="((ev)=>{blur(ev,liabilities,'payrollPayable',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('payrollPayable',i)?method.formateNum(liabilities.payrollPayable[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">应交税费</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.taxPayable[i]"
														@blur="((ev)=>{blur(ev,liabilities,'taxPayable',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('taxPayable',i)?method.formateNum(liabilities.taxPayable[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">其他应付款</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherPayables[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherPayables',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherPayables',i)?method.formateNum(liabilities.otherPayables[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">持有待售负债</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.saleForLiabilities[i]"
														@blur="((ev)=>{blur(ev,liabilities,'saleForLiabilities',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('saleForLiabilities',i)?method.formateNum(liabilities.saleForLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">一年内到期的非流动负债</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.illiquidLiabilitiesOne[i]"
														@blur="((ev)=>{blur(ev,liabilities,'illiquidLiabilitiesOne',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('illiquidLiabilitiesOne',i)?method.formateNum(liabilities.illiquidLiabilitiesOne[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">
													其他流动负债
													<span class="desc-container">
														<span></span>
														<div class="desc-main">
															<span></span>
															<p>此处应填写未在本会计报表内统计的所有流动负债科目汇总金额。</p>
														</div>
													</span>
												</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherNonLiabilities[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherNonLiabilities',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherNonLiabilities',i)?method.formateNum(liabilities.otherNonLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">流动负债合计</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.totalNonLiabilities[i]" disabled>
													<span class="showInput">
														{{judgingCondition('totalNonLiabilities',i)?method.formateNum(liabilities.totalNonLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">非流动负债：</span>
												<span v-for="(item,i) in yearList"></span>
											</li>
											<li>
												<span class="trTwo">长期借款</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.longLoan[i]"
														@blur="((ev)=>{blur(ev,liabilities,'longLoan',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('longLoan',i)?method.formateNum(liabilities.longLoan[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">应付债券</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.bondsPayable[i]"
														@blur="((ev)=>{blur(ev,liabilities,'bondsPayable',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('bondsPayable',i)?method.formateNum(liabilities.bondsPayable[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trThree">其中：优先股</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.bondsPreferredStock[i]"
														@blur="((ev)=>{blur(ev,liabilities,'bondsPreferredStock',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('bondsPreferredStock',i)?method.formateNum(liabilities.bondsPreferredStock[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trFour">永续债</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.bondsPerpetuaDebt[i]"
														@blur="((ev)=>{blur(ev,liabilities,'bondsPerpetuaDebt',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('bondsPerpetuaDebt',i)?method.formateNum(liabilities.bondsPerpetuaDebt[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">租赁负债</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.leaseLiabilities[i]"
														@blur="((ev)=>{blur(ev,liabilities,'leaseLiabilities',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('leaseLiabilities',i)?method.formateNum(liabilities.leaseLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">长期应付款</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.longPayment[i]"
														@blur="((ev)=>{blur(ev,liabilities,'longPayment',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('longPayment',i)?method.formateNum(liabilities.longPayment[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">预计负债</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.estimatedLiabilities[i]"
														@blur="((ev)=>{blur(ev,liabilities,'estimatedLiabilities',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('estimatedLiabilities',i)?method.formateNum(liabilities.estimatedLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">递延收益</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.deferredIncome[i]"
														@blur="((ev)=>{blur(ev,liabilities,'deferredIncome',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('deferredIncome',i)?method.formateNum(liabilities.deferredIncome[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">递延所得税负债</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.deferredLiability[i]"
														@blur="((ev)=>{blur(ev,liabilities,'deferredLiability',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('deferredLiability',i)?method.formateNum(liabilities.deferredLiability[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">
													其他非流动负债
													<span class="desc-container">
														<span></span>
														<div class="desc-main">
															<span></span>
															<p>此处应填写未在本会计报表内统计的所有非流动负债科目汇总金额。</p>
														</div>
													</span>
												</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherFixedLiabilities[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherFixedLiabilities',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherFixedLiabilities',i)?method.formateNum(liabilities.otherFixedLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">非流动负债合计</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.totalFixedLiabilities[i]" disabled>
													<span class="showInput">
														{{judgingCondition('totalFixedLiabilities',i)?method.formateNum(liabilities.totalFixedLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">负债总计</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.totalLiabilities[i]" disabled>
													<span class="showInput">
														{{judgingCondition('totalLiabilities',i)?method.formateNum(liabilities.totalLiabilities[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">
													所有者权益(或股东权益)：
													<span class="desc-container">
														<span></span>
														<div class="desc-main">
															<span></span>
															<p>是指所有者向企业投入的资本总和。</p>
														</div>
													</span>
												</span>
												<span v-for="(item,i) in yearList"></span>
											</li>
											<li>
												<span class="trTwo">实收资本(或股本)</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.paidUpCapital[i]"
														@blur="((ev)=>{blur(ev,liabilities,'paidUpCapital',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('paidUpCapital',i)?method.formateNum(liabilities.paidUpCapital[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">其他权益工具</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherEquity[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherEquity',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherEquity',i)?method.formateNum(liabilities.otherEquity[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trThree">其中：优先股</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.equityPreferredStock[i]"
														@blur="((ev)=>{blur(ev,liabilities,'equityPreferredStock',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('equityPreferredStock',i)?method.formateNum(liabilities.equityPreferredStock[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trFour">永续债</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.equityPerpetuaDebt[i]"
														@blur="((ev)=>{blur(ev,liabilities,'equityPerpetuaDebt',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('equityPerpetuaDebt',i)?method.formateNum(liabilities.equityPerpetuaDebt[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">资本公积</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.capitalReserve[i]"
														@blur="((ev)=>{blur(ev,liabilities,'capitalReserve',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('capitalReserve',i)?method.formateNum(liabilities.capitalReserve[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">减：库存股</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.treasuryShare[i]"
														@blur="((ev)=>{blur(ev,liabilities,'treasuryShare',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('treasuryShare',i)?method.formateNum(liabilities.treasuryShare[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">其他综合收益</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.otherIncome[i]"
														@blur="((ev)=>{blur(ev,liabilities,'otherIncome',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('otherIncome',i)?method.formateNum(liabilities.otherIncome[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">专项储备</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.appropriativeReserve[i]"
														@blur="((ev)=>{blur(ev,liabilities,'appropriativeReserve',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('appropriativeReserve',i)?method.formateNum(liabilities.appropriativeReserve[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">盈余公积</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.earnedSurplus[i]"
														@blur="((ev)=>{blur(ev,liabilities,'earnedSurplus',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('earnedSurplus',i)?method.formateNum(liabilities.earnedSurplus[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">未分配利润</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.undistributedProfit[i]"
														@blur="((ev)=>{blur(ev,liabilities,'undistributedProfit',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('undistributedProfit',i)?method.formateNum(liabilities.undistributedProfit[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">
													归属于母公司所有者权益合计
													<span class="desc-container">
														<span></span>
														<div class="desc-main">
															<span></span>
															<p>是指公司集团的所有者权益中归属于母公司所有者权益的部分。</p>
														</div>
													</span>
												</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.totalParentEquity[i]" disabled>
													<span class="showInput">
														{{judgingCondition('totalParentEquity',i)?method.formateNum(liabilities.totalParentEquity[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">
													少数股东权益
													<span class="desc-container">
														<span></span>
														<div class="desc-main">
															<span></span>
															<p>是指母公司以外的的人拥有的权益，此项针对合并报表企业，如果无少数股东权益或非合并报表企业，则填零。</p>
														</div>
													</span>
												</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.minorityEquity[i]"
														@blur="((ev)=>{blur(ev,liabilities,'minorityEquity',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput" @click="inputFocus($event)">
														{{judgingCondition('minorityEquity',i)?method.formateNum(liabilities.minorityEquity[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">所有者权益（或股东权益)合计</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.totalOwnershipInterest[i]" disabled>
													<span class="showInput">
														{{judgingCondition('totalOwnershipInterest',i)?method.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">负债和所有者权益(或股东权益)总计</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="liabilities.totalLiabilitiesEquity[i]" disabled>
													<span class="showInput">
														{{judgingCondition('totalLiabilitiesEquity',i)?method.formateNum(liabilities.totalLiabilitiesEquity[i],2):'-'}}
													</span>
												</span>
											</li>



										</ul>

										<p class="line"></p>
									</div>


								</div>

							</div>



						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '@/common/fillin/examineTitle.vue'
	import examineNav from '../../nav/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				type: '13',
				yearList: ['xxxx年', 'xxxx年'],
				id: '',
				isCacheValue: '',
				liabilities: {
					monetaryCapital: [],
					tradingAssets: [],
					derivativeAssets: [],
					billReceivable: [],
					receivables: [],
					accountReceivable: [],
					prepayment: [],
					otherReceivables: [],
					inventory: [],
					contractualAssets: [],
					saleForAssets: [],
					illiquidAssetsOne: [],
					otherCurrentAssets: [],
					totalCurrentAssets: [],
					debtInvestment: [],
					otherDebtInvestment: [],
					longAeceivables: [],
					longInvestment: [],
					otherEquityInvestment: [],
					otherIlliquidAssets: [],
					investmentProperty: [],
					fixedAssets: [],
					constructionProjects: [],
					productiveAsset: [],
					oilAndGasAssets: [],
					rightAssets: [],
					netWorth: [],
					developExpense: [],
					goodwill: [],
					longExpenses: [],
					deferredAssets: [],
					otherNonCurrentAssets: [],
					totalNonCurrentAssets: [],
					totalAssets: [],
					shortLoan: [],
					transactionLiabilities: [],
					derivativeLiabilities: [],
					notesPayable: [],
					accountsPayable: [],
					advanceReceipt: [],
					contractualLiabilities: [],
					payrollPayable: [],
					taxPayable: [],
					otherPayables: [],
					saleForLiabilities: [],
					illiquidLiabilitiesOne: [],
					otherNonLiabilities: [],
					totalNonLiabilities: [],
					longLoan: [],
					bondsPayable: [],
					bondsPreferredStock: [],
					bondsPerpetuaDebt: [],
					leaseLiabilities: [],
					longPayment: [],
					estimatedLiabilities: [],
					deferredIncome: [],
					deferredLiability: [],
					otherFixedLiabilities: [],
					totalFixedLiabilities: [],
					totalLiabilities: [],
					paidUpCapital: [],
					otherEquity: [],
					equityPreferredStock: [],
					equityPerpetuaDebt: [],
					capitalReserve: [],
					treasuryShare: [],
					otherIncome: [],
					appropriativeReserve: [],
					earnedSurplus: [],
					undistributedProfit: [],
					totalParentEquity: [],
					minorityEquity: [],
					totalOwnershipInterest: [],
					totalLiabilitiesEquity: []

				},



			}
		},
		mounted() {
			this.getBalanceSheet()

			window.addEventListener('scroll', this.handleScroll);

		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			handleScroll() {
				const navbar = document.getElementsByClassName('unit')[0];
				const sticky = navbar.offsetTop;

				if (window.pageYOffset >= sticky) {
					$('.yeaAccount').addClass('fixed-thead').css({
						'top': this.method.ratioEquality(1854, 90, this.width - 100) + 115 + 'px',
						'left': $('.fillinContent').offset().left + 35 - window.pageXOffset + 'px',
						'width': $('.professionalForm').width()
					});

				} else {
					$('.yeaAccount').removeClass('fixed-thead')
					$('.yeaAccount').removeAttr('style')
				}

			},
			judgingCondition: function(key, i, index) {
				if (this.liabilities[key][i] === 0) {
					return true
				} else if (this.liabilities[key][i] != 0 && this.liabilities[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			blur() {

			},
			getBalanceSheet() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();
						var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
						this.yearList = JSON.parse(res.data.content.liabilities.liabilities).year ? JSON.parse(res.data.content
							.liabilities.liabilities).year : res.data.content.liabilities.year;


						var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
						var returnliabilitiesKey = Object.keys(JSON.parse(res.data.content.liabilities
							.liabilities)); //后台数据返回的资产负债表对象的 key
						liabilitiesKey.forEach((elem, index) => {
							returnliabilitiesKey.forEach((item, i) => {
								this_.yearList.forEach((yearItem, y) => {
									if (elem == item) {
										this_.liabilities[elem][y] = JSON.parse(res.data.content.liabilities.liabilities)[elem]
											[y];
									} else if (!this_.liabilities[elem]) {
										if (this_.yearList.length == 2) {
											this_.liabilities[elem] = ['', '']
										}
									}
								})
							})
						})


					})
			}

		}
	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";

	.professionalForm li .showInput {
		width: 83%;
	}
</style>