<template>
	<!-- 'paddingBottom':method.ratioEquality(1854,90,width-100)/3.2+'px' -->
	<div class="headerBackground">
		<div class="evaluateHeader"
			:style="{'height':method.ratioEquality(1854,90,width-100)+'px','paddingBottom':method.ratioEquality(1854,90,width-100)/3.2+'px'}">
			<!-- <div class="evaluateHeader" :style="{'width':method.ratioEqualityHeight(1854,90,75)+'px'}"> -->
			<div><img :src="require('@/assets/img/logo/dvWhite.png')" alt="logo"
					:style="{'width':width==1200?'96px':'7.5vw','cursor':'pointer'}" @click="goHome" /></div>
			<div
				:style="{'lineHeight':method.ratioEquality(1854,90,width-100)-4+'px','fontSize':(width==1200?'25px':'1.7vw')}">
				{{ this.type==14?'数据价值测评':this.type==13?'数据估值(专业版)':'数据估值(快速版)'}}
			</div>
			<div>
				<span @click="goHome" :style="{'lineHeight':method.ratioEquality(1854,90,width-100)-4+'px'}">首页</span>
				<div class="login">
					<img src="../../assets/img/dataEvaluation/my.png" />
					<span class="tel" @click="login"
						:style="{'lineHeight':method.ratioEquality(1854,90,width-100)-4+'px'}">{{logoShow?'登录':username}}</span>
					<ul :class="logoShow?'loginShow':'account-sel'">
						<li><a :href="[this.domain.testUrl+'pages/myValuation.html?type=13']">我的报告</a></li>
						<li><router-link tag="a" to="/login">退出</router-link></li>
					</ul>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import qs from 'qs'
	import axios from 'axios'
	import '../../assets/css/myAlertData.css';

	export default {
		props: {
			stage: {
				type: [String, Number],
				required: false
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
		
		},
		data() {
			return {
				width: window.innerWidth,
				logoShow: true,
				username: localStorage.userName,
				flowUrl: '',
				logo: ''
			}
		},
		mounted() {
			this.set()
			window.addEventListener('resize', () => {
				this.set()
			})

			this.isLogin()

		},
		methods: {
			set() {
				if (window.innerWidth < 1200) {
					this.width = 1200
				} else {
					this.width = window.innerWidth
				}
			},
			getPageSetup() {
				this.$post(this.domain.dataRealization + 'pageSetup/getPageSetup', qs.stringify({
					channelCode: window.channel,
					setupType: 7
				})).then(res => {
					var setupContent = JSON.parse(res.data.content.setupContent)
					this.logo = setupContent.appraise.imgPath
				})
			},
			isLogin() {
				if (!localStorage.userName || !localStorage.userToken) {
					this.logoShow = true
				} else {
					this.logoShow = false
				}
			},
			login() {
				if (this.logoShow) {
					this.$router.push({
						name: 'login',
						path: '/login'
					})
				}
			},
			goHome() {
				if(this.type==15||this.type==14){
					window.location.href=this.domain.testUrl
				}else{
					this.$router.push({
						name: 'Home',
						path: '/'
					})
				}
				
			}
		},


	}
</script>

<style scoped lang="less">
	.evaluateHeader {
		width: calc(100% - 100px);
		min-width: 1100px;
		background: url(../../assets/img/dataEvaluation/header.png) center center no-repeat;
		background-size: 100% 100%;
		margin: 15px auto 0;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;



		>div {
			height: 100%;
			text-align: center;
			position: relative;
		}

		>div:nth-child(1) {
			width: 26%;
		}

		>div:nth-child(2) {
			// font-size: 2.5vw;  //vw单位是根据视口的宽度来确定大小的，1vw等于视口宽度的1%
			font-weight: bold;
			color: #fff;
		}

		>div:nth-child(3) {
			width: 26%;
			display: flex;
			justify-content: center;
			// align-items: end;

			span {
				font-size: 14px;
				color: #fff;
				cursor: pointer;
				margin-right: 8px;
			}

			img {
				width: 20px;
				vertical-align: middle;
			}

			.tel {
				width: 76px;
				// height: 20px;
				// line-height: 20px;
				font-size: 14px;
				color: #fff;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin-left: 4px;
			}

			.loginShow {
				display: none;
			}

			.account-sel {
				width: 120px;
				text-align: center;
				border-top: 0;
				background: #fff;
				border-radius: 5px;
				padding: 6px 0;
				display: none;
				position: absolute;
				bottom: -75px;
				left: 25px;
				z-index: 999;

				li {

					a {
						width: 100%;
						font-size: 14px;
						line-height: 30px;
						cursor: pointer;
						text-align: left;
						margin-left: 16px;
						box-sizing: content-box;
						color: #1459F4;
					}
				}

				li a:hover {
					color: #002d93;
				}
			}

			.login {
				width: 41%;
				text-align: left;
				padding-bottom: 17px;
				position: relative;
				// top: 17px;
			}

			.login:hover ul:not(.loginShow) {
				display: block;
			}

		}



	}
</style>