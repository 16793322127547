<template>
	<div class="main">
		<Header :type="type" />
		<div id="viewInformationPage">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav />
				</div>
				<div class="viewInfo">
					<div>
						<div class="audit_refuse" v-if="this.$route.query.auditStatus==2">
							<img src="../../assets/img/examine/underReview.png" />
							<div>
								<p class="auditStatusAlert" style="color: #FDFF45;">您的测评报告审核中，请耐心等待！</p>
								<p class="contactWay">如有疑问请拨打心流客服电话：+86(10)87235120</p>
							</div>
						</div>
						<div class="audit_refuse" v-else>
							<img src="../../assets/img/examine/failAudit.png" />
							<div>
								<p class="auditStatusAlert" style="color: red;">您的测评报告审核未通过！</p>
								<p style="font-size: 14px;color: #29FFFC;margin-top: 5px;">您可修改信息，重新提交审核</p>
								<p class="contactWay">如有疑问请拨打心流客服电话：+86(10)87235120</p>
								<button @click="revamp">立即修改</button>
							</div>
							<div class="notPassingReason">
								<i>审核未通过原因：</i>
								<p>
								<pre>{{auditConent}}</pre>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '../../common/fillin/examineTitle.vue'
	import examineNav from '../../common/fillin/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				auditConent: '',
				type:14

			}
		},
		created() {
			if (this.$route.query.userToken) {
				localStorage.userToken = this.$route.query.userToken
			}

			if (this.$route.query.encipher) {
				localStorage.userName = this.method.base64ToUtf8(this.$route.query.encipher)
			}

		},
		mounted() {
			this.getAuditNotPassInfo()
		},
		methods: {
			getAuditNotPassInfo() {
				this.$post(this.domain.valuationProject + 'auditInfo/getAuditNotPassInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.auditConent = res.data.content.auditConent
					})
			},
			revamp() {
				this.$router.push({
					path: '/basicInformationEvaluation',
					query: {
						type: this.$route.query.type,
						reportId: this.$route.query.reportId
					}
				})
			}
		}
	}
</script>


<style scoped lang="less">
	.viewInfo {
		>div {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
		}

		.audit_refuse {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			>img {
				width: 26%;
				margin-right: 30px;
			}

			.auditStatusAlert {
				font-size: 16px;
			}

			.contactWay {
				font-size: 14px;
				color: #fff;
				margin-top: 10px;
			}

			button {
				width: 95px;
				height: 23px;
				border: 1px solid #29FFFC;
				border-radius: 11px;
				font-size: 14px;
				color: #29FFFC;
				background: transparent;
				margin-top: 12px;
				cursor: pointer;
			}

		}

		.notPassingReason {
			width: 100%;
			display: flex;
			margin-top: 45px;

			>i {
				width: 113px;
				font-size: 14px;
				color: #fff;

			}

			>i+p {
				width: calc(100% - 113px);
				font-size: 14px;
				color: #fff;
				padding-bottom: 30px;

				pre {
					width: 100%;
					margin: 0;
					text-align: justify;
					white-space: pre-wrap;
					/* 允许文本自动换行 */
				}
			}
		}
	}
</style>