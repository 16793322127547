<template>
	<div>
		<div class="tools">
			<button @click="pdf()" v-if="pageInfo.isTestAccount==0||this.$route.query.admin == 1">导出PDF</button>
		</div>
		<div id="pageContent">
			<!-- 封面 -->
			<div class="homePage page">
				<div class="content" id="homePage">
					<div class="logoBox">
						<img id="logo" src="../../../assets/img/logo/flowDataBlack.png" alt="线上估值-心流" />
					</div>
					<p class="companyName" v-text="pageInfo.baseInfo.companyName"></p>
					<div class="homePageLine"></div>
					<div class="reportType">数据估值报告</div>
					<h6>（专业版）</h6>
					<h4>报告编号：{{pageInfo.reportNumber}}</h4>
					<div class="page-one-footer">
						<div class="date" v-text="pageInfo.createTime ? replaceAll(pageInfo.createTime.split(' ')[0]) : ''"></div>
						<p>
							<span>北京心流数据科技有限公司保留对此报告的最终解释权</span>
						</p>
					</div>
				</div>
			</div>
			<!-- 盖章页 -->
			<div class="page">
				<!--内容-->
				<div class="content page-content" id="page-stamp">
					<p class="stamp">
						依据《中共中央、国务院关于构建数据基础制度更好发挥数据要素作用的意见》、《企业数据资源相关会计处理暂⾏规定》 （财会〔2023〕11号） 、《数据资产评估指导意见》（中评协〔2023〕17
						号）、《企业会计准则》和《中小微企业估值规范》等政策规章，基于心流自研的估值指标体系及Ai估值技术，贵公司无形资产-数据资源在估值基准日{{method.reportFormatDate(pageInfo.reportBaseDate)}}的市场价值为（人民币）：
					</p>
					<div class="detail_result">
						<div>
							<h4 v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)">0.00</h4>
						</div>
					</div>
					<div class="stamptime">
						<p>北京心流数据科技有限公司</p>
						<p
							v-text="pageInfo.updateTime ? dateFormate(pageInfo.createTime.split(' ')[0]) : dateFormate(pageInfo.createTime.split(' ')[0])">
						</p>
					</div>

				</div>
			</div>
			<!--目录页-->
			<div class="page">
				<div class="content page-content page-catalogue" id="page-catalogue">
					<h2>目录</h2>
					<div class="generalize">
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">一、基本信息</span>
								<span class="catalogue-pagination">01</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">二、估值对象、估值范围、估值基准日及价值</span>
								<span class="catalogue-pagination">02</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">三、综合得分</span>
								<span class="catalogue-pagination">
									{{rangePageNum+1<10?'0'+(rangePageNum+1):rangePageNum+1}}
								</span>
							</div>
						</div>
						<div class="haveTwoLevels">
							<div class="catalogue-category">
								<span class="spreadhead">四、软性指标</span>
								<span class="catalogue-pagination">{{rangePageNum+2<10?'0'+(rangePageNum+2):rangePageNum+2}}</span>
							</div>
						</div>
						<ul>
							<li>
								<div>
									<span>基础指标</span>
									<span>{{rangePageNum+2<10?'0'+(rangePageNum+2):rangePageNum+2}}</span>
								</div>
							</li>
							<li>
								<div>
									<span>风险指标</span>
									<span>{{rangePageNum+3<10?'0'+(rangePageNum+3):rangePageNum+3}}</span>
								</div>
							</li>
							<li>
								<div>
									<span>质量指标</span>
									<span>{{rangePageNum+4<10?'0'+(rangePageNum+4):rangePageNum+4}}</span>
								</div>
							</li>
							<li>
								<div>
									<span>流通指标</span>
									<span>{{rangePageNum+5<10?'0'+(rangePageNum+5):rangePageNum+5}}</span>
								</div>
							</li>
							<li>
								<div>
									<span>经济指标</span>
									<span>{{rangePageNum+6<10?'0'+(rangePageNum+6):rangePageNum+6}}</span>
								</div>
							</li>
						</ul>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">五、MLM估值</span>
								<span class="catalogue-pagination">{{rangePageNum+7<10?'0'+(rangePageNum+7):rangePageNum+7}}</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">六、NPCM估值</span>
								<span class="catalogue-pagination">{{rangePageNum+8<10?'0'+(rangePageNum+8):rangePageNum+8}}</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">七、数据资源概况</span>
								<span class="catalogue-pagination">{{rangePageNum+9<10?'0'+(rangePageNum+9):rangePageNum+9}}</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">八、营业收入预测</span>
								<span class="catalogue-pagination">
									{{lastPageEven?rangePageNum+9+newDataAsset.length:rangePageNum+8+newDataAsset.length}}
								</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">九、净利润预测</span>
								<span class="catalogue-pagination">
									{{rangePageNum+8+newDataAsset.length+forecastPaging.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)}}
								</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">十、估值假设</span>
								<span class="catalogue-pagination">
									{{rangePageNum+9+newDataAsset.length+forecastPaging.length+netProfitPaging.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)}}
								</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">十一、估值结果</span>
								<span class="catalogue-pagination">
									{{rangePageNum+10+newDataAsset.length+forecastPaging.length+netProfitPaging.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)}}
								</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">十二、特别事项说明和瑕疵事项</span>
								<span class="catalogue-pagination">
									{{rangePageNum+10+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
								</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">十三、估值报告使用限制说明</span>
								<span class="catalogue-pagination">
									{{rangePageNum+10+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
								</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">十四、估值报告解释权</span>
								<span class="catalogue-pagination">
									{{rangePageNum+10+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
								</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">十五、附件</span>
								<span class="catalogue-pagination">
									{{rangePageNum+11+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
								</span>
							</div>
						</div>
						<div>
							<div class="catalogue-category">
								<span class="spreadhead">十六、免责声明</span>
								<span class="catalogue-pagination">
									{{rangePageNum+18+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
								</span>
							</div>
						</div>

					</div>

				</div>
			</div>
			<!-- 基本信息 -->
			<div class="page">
				<div class="content page-content" id="page-two">
					<div class="baseInfo">
						<p class="broadCategories">一、企业概况</p>
						<p class="broadHeading">（一）基本信息</p>

						<div class="baseInfoCont">
							<span id="companyName" v-text="pageInfo.baseInfo.companyName"></span>
							<ul>
								<li>
									<span style="vertical-align: top;">所属行业：</span>
									<span id="industry"
										v-text="pageInfo.baseInfo.industryName + '-' + pageInfo.baseInfo.industryChildName"></span>
								</li>
								<li>
									<span>详细地址：</span>
									<span id="companyLocation"
										v-text="pageInfo.baseInfo.address ? pageInfo.baseInfo.address : '暂无数据'"></span>
								</li>
								<li>
									<span>统一社会信用代码：</span>
									<span id="socialCreditCode" v-text="pageInfo.baseInfo.creditCode"></span>
								</li>
								<li>
									<span>法定代表人：</span>
									<span id="corporateRepresentative"
										v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople : '暂无数据'"></span>
								</li>
								<li>
									<span>成立日期：</span>
									<span id="establishmentDate" v-text="dateFormate(pageInfo.baseInfo.establishDate)"></span>
								</li>
								<li>
									<span>注册资本：</span>
									<span id="establishmentDate"
										v-text="pageInfo.baseInfo.regCapital ? method.formateNum(pageInfo.baseInfo.regCapital,2)+'万元' : '暂无数据'"></span>
								</li>
								<li>
									<span>数据量级：</span>
									<span v-text="pageInfo.dataLevel?pageInfo.dataLevel.optionName.substring(2):'暂无数据'"></span>
								</li>
								<li>
									<span>数据资源所处周期：</span>
									<span v-text="pageInfo.dataPeriod?pageInfo.dataPeriod.optionName.substring(2):'暂无数据'"></span>
								</li>
								<li>
									<span>权属情况：</span>
									<span v-text="pageInfo.dataOwnership?pageInfo.dataOwnership.optionName.substring(2):'暂无数据'"></span>
								</li>
								<li>
									<span>估值目的：</span>
									<span v-text="pageInfo.baseInfo.purpose?pageInfo.baseInfo.purpose:'暂无数据'"></span>
								</li>
							</ul>
						</div>
						<div class="equityStructure">
							<p class="broadHeading">（二）股权结构</p>
							<div v-if="pageInfo.baseInfo.holderRation">
								<img src="../../../assets/img/report/chart.png" class="piePedestal" alt="饼图-心流">
								<Draw3DPieCanvas :id="'stockStructurePie'" :chartData="pageInfo.baseInfo.holderRation"
									v-if="initStatus" />
							</div>
							<div v-else style="padding: 20px 0 0 74px;font-size: 20px;color:#000;">暂无数据</div>

						</div>
					</div>
					<div class="pagination">01</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-scope">
					<p class="broadCategories">二、估值对象、估值范围、估值基准日及价值</p>
					<p class="broadHeading">（一）估值对象</p>
					<p class="text">
						{{pageInfo.baseInfo.companyName}}填列的各项无形资产-数据资源。
					</p>
					<p class="text secondLevelText">
						提示：本报告以填列的无形资产-数据资源为估值对象，不包括确认为存货-数据资源、开发支出-数据资源的资产。
					</p>
					<p class="broadHeading">（二）估值范围</p>
					<p class="text" style="margin-bottom: 14px;">
						{{pageInfo.baseInfo.companyName}}申报的数据资源具体如下：
					</p>

					<div class="valuationRange" v-if="initStatus">
						<div>
							<p class="rangeHead">
								<img src="../../../assets/img/report/rangeHead.png" />
								<span>{{multipleRulesPaged[0][0].category?'业务类型':'序号'}}</span>
								<span>数据资源名称</span>
							</p>
							<table border="0" style="border-collapse: collapse;">
								<tbody>
									<tr v-for="(item, index) in multipleRulesPaged[0]" :key="index">
										<td v-if="item.category===''&&resourceType==0">{{index+1}}</td>
										<td :rowspan="getPageLength(0, item.category,'multipleRulesPaged')"
											v-else-if="index === 0 || item.category !== multipleRulesPaged[0][index - 1].category">
											{{ item.category }}
										</td>

										<td>{{ item.resourceName }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div v-if="initStatus&&multipleRulesPaged.length==1&&multipleRulesPaged[0].length<=13"
						style="margin-top: 38px;">
						<p class="broadHeading">（三）估值基准日</p>
						<p class="text secondLevelText">
							估值基准日是{{method.reportFormatDate(pageInfo.reportBaseDate)}}。估值基准日在考虑经济行为的实现、会计核算期等因素后确定。估值基准日的确定对估值结果的影响符合常规情况，无特别影响因素。本次估值的取价标准为估值基准日有效的价格标准。
						</p>
						<p class="broadHeading">（四）价值类型</p>
						<p class="text secondLevelText">
							本次估值对估值的市场条件、估值对象的使用条件无特别限制和要求，故选择市场价值作为估值结论的价值类型。市场价值是指自愿买方和自愿卖方在各自理性行事且未受任何强迫的情况下，估值对象在估值基准日进行正常公平交易的价值估计数额。
						</p>
					</div>

					<div class="pagination">02</div>
				</div>
			</div>

			<div class="page" v-for="(page, pageIndex) in multipleRulesPaged.slice(1)" v-if="initStatus">
				<div class="content page-content" :id="'page-scope_'+pageIndex">
					<div class="valuationRange">
						<div>
							<p class="rangeHead">
								<img src="../../../assets/img/report/rangeHead.png" />
								<span>{{multipleRulesPaged[0][0].category?'业务类型':'序号'}}</span>
								<span>数据资源名称</span>
							</p>
							<table border="0" style="border-collapse: collapse;">
								<tbody>
									<tr v-for="(item, index) in page" :key="index">
										<td v-if="index === 0&&item.category==''">{{multipleRulesPaged[pageIndex].length+index+1}}</td>
										<td :rowspan="getPageLength(pageIndex+1, item.category,'multipleRulesPaged')"
											v-else-if="index === 0 || item.category !== page[index - 1].category">
											{{ item.category }}
										</td>

										<td>{{ item.resourceName }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div
						v-if="initStatus&&pageIndex==multipleRulesPaged.length-2&&multipleRulesPaged.length>1&&multipleRulesPaged[multipleRulesPaged.length-1].length<=19"
						style="margin-top: 38px;">
						<p class="broadHeading">（三）估值基准日</p>
						<p class="text secondLevelText">
							估值基准日是{{method.reportFormatDate(pageInfo.reportBaseDate)}}。估值基准日在考虑经济行为的实现、会计核算期等因素后确定。估值基准日的确定对估值结果的影响符合常规情况，无特别影响因素。本次估值的取价标准为估值基准日有效的价格标准。
						</p>
						<p class="broadHeading">（四）价值类型</p>
						<p class="text secondLevelText">
							本次估值对估值的市场条件、估值对象的使用条件无特别限制和要求，故选择市场价值作为估值结论的价值类型。市场价值是指自愿买方和自愿卖方在各自理性行事且未受任何强迫的情况下，估值对象在估值基准日进行正常公平交易的价值估计数额。
						</p>
					</div>

					<div class="pagination">{{3+pageIndex<10?'0'+(3+pageIndex):3+pageIndex}}</div>
				</div>
			</div>

			<div class="page"
				v-if="(initStatus&&multipleRulesPaged.length>1&&multipleRulesPaged[multipleRulesPaged.length-1].length==26) || initStatus&&multipleRulesPaged.length==1&&multipleRulesPaged[0].length==19">
				<div class="content page-content" id="page-scopeLast">
					<div style="margin-top: 38px;">
						<p class="broadHeading">（三）估值基准日</p>
						<p class="text secondLevelText">
							估值基准日是{{method.reportFormatDate(pageInfo.reportBaseDate)}}。估值基准日在考虑经济行为的实现、会计核算期等因素后确定。估值基准日的确定对估值结果的影响符合常规情况，无特别影响因素。本次估值的取价标准为估值基准日有效的价格标准。
						</p>
						<p class="broadHeading">（四）价值类型</p>
						<p class="text secondLevelText">
							本次估值对估值的市场条件、估值对象的使用条件无特别限制和要求，故选择市场价值作为估值结论的价值类型。市场价值是指自愿买方和自愿卖方在各自理性行事且未受任何强迫的情况下，估值对象在估值基准日进行正常公平交易的价值估计数额。
						</p>
					</div>
					<div class="pagination">
						{{2+multipleRulesPaged.length<10?'0'+(2+multipleRulesPaged.length):2+multipleRulesPaged.length}}
					</div>
				</div>
			</div>


			<!-- 软性指标 -->
			<div class="page">
				<div class="content page-content" id="page-three">
					<p class="broadCategories" style="margin-bottom: 20px;">三、综合得分</p>
					<p class="scoreDescription">
						综合得分是评估数据价值的重要标准，包括数据的基础指标、风险指标、经济指标、质量指标、流通指标、财务指标等维度。通过采用层次分析、聚类分析和专家打分等多种方法计算各项指标的得分及权重，对数据进行全面分析和评估，帮助企业更准确地识别和挖掘数据的潜在价值，为业务决策和创新提供有力的数据支持。
					</p>
					<div class="softIndexScore">
						<img src="../../../assets/img/report/synthesisScore.png" />
						<div>
							<p>
								<span>{{Number(pageInfo.totalScore).toFixed(2)}}分</span>
								<span>综合得分</span>
							</p>
						</div>
					</div>

					<p class="broadCategories">四、软性指标</p>
					<ul class="indexScore">
						<li>
							<div class="valueResultTitle">
								<img src="../../../assets/img/report/icon17.png" class="icon_" alt="心流">
								<span>软性指标</span>
							</div>
							<div class="indicators">
								<img src="../../../assets/img/report/softIndicators.png" />
								<p v-for="(item,i) in question">
									<span>{{item.name}}</span>
									<span>{{item.value}}分</span>
								</p>
							</div>
						</li>
						<li>
							<div class="valueResultTitle">
								<img src="../../../assets/img/report/icon22.png" class="icon_" alt="心流">
								<span>财务指标</span>
							</div>
							<div class="financeIndicators">
								<img src="../../../assets/img/report/financeIndicators.png" />
								<p v-for="(item,i) in financial">
									<span>{{item.name}}</span>
									<span>{{item.value}}分</span>
								</p>
							</div>
						</li>
					</ul>

					<div class="pagination">{{rangePageNum+1<10?'0'+(rangePageNum+1):rangePageNum+1}}</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-four">
					<p class="broadHeading">（一）{{question[0].name}} &nbsp;{{question[0].value}}分</p>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../../assets/img/report/basicIndex.png" />
						<ul class="basicIndexScore">
							<li style="left: 17px;">
								<p>{{pageInfo.industry[0][0].value}}分</p>
								<p>{{pageInfo.industry[0][0].name}}</p>
							</li>
							<li style="right: 17px;">
								<p>{{pageInfo.industry[0][1].value}}分</p>
								<p>{{pageInfo.industry[0][1].name}}</p>
							</li>
						</ul>
						<div>
							<img src="../../../assets/img/report/basicIndexIcon.svg" />
							<p>{{question[0].name}}</p>
						</div>
					</div>
					<div class="guidelines">
						<p v-for="(item,index) in pageInfo.industry[0]">{{item.description}}</p>
					</div>
					<div class="pagination">{{rangePageNum+2<10?'0'+(rangePageNum+2):rangePageNum+2}}</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-five">
					<p class="broadHeading">（二）{{question[1].name}} &nbsp;{{question[1].value}}分</p>
					<div class="indicator" v-if="pageInfo.industry" style="margin-top: 106px;">
						<img src="../../../assets/img/report/riskIndicator.png" />
						<ul class="basicIndexScore riskIndicatorScore">
							<li style="left: 17px;">
								<p>{{pageInfo.industry[1][0].value}}分</p>
								<p>{{pageInfo.industry[1][0].name}}</p>
							</li>
							<li style="right: 17px;">
								<p>{{pageInfo.industry[1][1].value}}分</p>
								<p>{{pageInfo.industry[1][1].name}}</p>
							</li>
						</ul>
						<div>
							<img src="../../../assets/img/report/riskIndicatorIcon.png" />
							<p>{{question[1].name}}</p>
						</div>
					</div>
					<div class="guidelines">
						<p v-for="(item,index) in pageInfo.industry[1]">{{item.description}}</p>
					</div>
					<div class="pagination">{{rangePageNum+3<10?'0'+(rangePageNum+3):rangePageNum+3}}</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-six">
					<p class="broadHeading">（三）{{question[2].name}} &nbsp;{{question[2].value}}分</p>
					<div class="indicator" v-if="pageInfo.industry" style="margin-top: 129px;">
						<img src="../../../assets/img/report/qualityIndex.png" />
						<ul class="qualityIndexScore">
							<li>
								<div>
									<div>
										<p>{{pageInfo.industry[2][0].name}}</p>
										<p>{{pageInfo.industry[2][0].value}}分</p>
									</div>
								</div>
								<img src="../../../assets/img/report/qualityIndexScore1.png" />
							</li>
							<li style="top: 258px;left: -60px;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][1].name}}</p>
										<p>{{pageInfo.industry[2][2].value}}分</p>
									</div>
								</div>
								<img src="../../../assets/img/report/qualityIndexScore2.png" />
							</li>
							<li style="top: 394px;left: 54%;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][2].name}}</p>
										<p>{{pageInfo.industry[2][2].value}}分</p>
									</div>
								</div>
								<img src="../../../assets/img/report/qualityIndexScore3.png" />
							</li>
							<li style="top: 258px;right: -64%;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][3].name}}</p>
										<p>{{pageInfo.industry[2][3].value}}分</p>
									</div>
								</div>
								<img src="../../../assets/img/report/qualityIndexScore4.png" />
							</li>
							<li style="left: 108%;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][4].name}}</p>
										<p>{{pageInfo.industry[2][4].value}}分</p>
									</div>
								</div>
								<img src="../../../assets/img/report/qualityIndexScore5.png" />
							</li>
						</ul>
						<div>
							<p>{{question[2].name}}</p>
						</div>
					</div>
					<div class="guidelines" style="margin-top: 180px;">
						<p v-for="(item,index) in pageInfo.industry[2]">{{item.description}}</p>
					</div>
					<div class="pagination">{{rangePageNum+4<10?'0'+(rangePageNum+4):rangePageNum+4}}</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-eight">
					<p class="broadHeading">（四）{{question[3].name}} &nbsp;{{question[3].value}}分</p>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../../assets/img/report/costIndex.png">
						<ul class="costIndexScore">
							<li style="left: 0px;">
								<p>{{pageInfo.industry[3][0].value}}分</p>
								<p>{{pageInfo.industry[3][0].name}}</p>
							</li>
							<li style="right: 40%;top: 307px;">
								<p>{{pageInfo.industry[3][1].value}}分</p>
								<p>{{pageInfo.industry[3][1].name}}</p>
							</li>
							<li style="right: 0px;">
								<p>{{pageInfo.industry[3][2].value}}分</p>
								<p>{{pageInfo.industry[3][2].name}}</p>
							</li>
						</ul>
						<div>
							<div>
								<img src="../../../assets/img/report/indicatorCosts.png">
								<p>{{question[3].name}}</p>
							</div>
						</div>
					</div>
					<div class="guidelines" style="margin-top: 50px;">
						<p v-for="(item,index) in pageInfo.industry[3]">{{item.description}}</p>
					</div>
					<div class="pagination">{{rangePageNum+5<10?'0'+(rangePageNum+5):rangePageNum+5}}</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-seven">
					<p class="broadHeading">（五）{{question[4].name}} &nbsp;{{question[4].value}}分</p>
					<div class="indicator" v-if="pageInfo.industry" style="margin-top: 100px;margin-bottom: 0;">
						<img src="../../../assets/img/report/economicIndicators.png">
						<ul class="economicScore">
							<li style="left: -70px;">
								<span>{{pageInfo.industry[4][0].name}}</span>
								<span>{{pageInfo.industry[4][0].value}}分</span>
							</li>
							<li style="right: -70px;">
								<span>{{pageInfo.industry[4][1].name}}</span>
								<span>{{pageInfo.industry[4][1].value}}分</span>
							</li>
							<li style="left: -122px;top: 140px;">
								<span>{{pageInfo.industry[4][2].name}}</span>
								<span>{{pageInfo.industry[4][2].value}}分</span>
							</li>
							<li style="right: -122px;top: 140px;">
								<span>{{pageInfo.industry[4][3].name}}</span>
								<span>{{pageInfo.industry[4][3].value}}分</span>
							</li>
						</ul>
						<div>
							<p>{{question[4].name}}</p>
						</div>
					</div>
					<div class="guidelines" style="margin-top: 28px;">
						<p v-for="(item,index) in pageInfo.industry[4]">{{item.description}}</p>
					</div>
					<div class="pagination">{{rangePageNum+6<10?'0'+(rangePageNum+6):rangePageNum+6}}</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-nine">
					<p class="broadCategories">五、MLM估值</p>
					<p style="font-size: 20px;color: #282828;line-height: 42px;text-align: justify;text-indent: 2em;">
						MLM（Machine Learning Method，机器学习法）是指通过PCA主成分析、对比学习、知识图谱、混合专家模型等多种机器学习/深度学习方法。
					</p>
					<div class="profitData" id="profitData">
						<svg width='480' height='403'>
							<text class="rotateYear" :x="profitDataYearX[index]" y="76" transform='rotate(-17 280 100)'
								v-for="(item, index) in boxhead">{{item}}</text>
							<text class="rotateData" :x="profitDataIncomeX[index]" y="140" transform='rotate(-17 280 100)'
								v-for="(item, index) in income" :key="index">{{index==0?item:method.formateNum(item, 2)}}</text>
							<text class="rotateDataBlue" :x="profitDataNetAssetX[index]" :y="profitDataNetAssetY[index]"
								transform='rotate(-16 280 100)' v-for="(item, index) in netAsset"
								:key="index+'1'">{{index==0?item:method.formateNum(item, 2)}}</text>
							<text class="rotateData" :x="profitDataNetProfitX[index]" y="224" transform='rotate(-16 280 100)'
								v-for="(item, index) in netProfit" :key="index+'2'">{{index==0?item:method.formateNum(item, 2)}}</text>

							<text class="rotateDataBlue" x="97" y="258" transform='rotate(-17 280 100)'
								style="font-weight: bold;">公司息税折旧</text>
							<text class="rotateDataBlue" x="97" y="276" transform='rotate(-17 280 100)'
								style="font-weight: bold;">摊销前利润</text>

							<text class="rotateDataBlue" x="288" y="270"
								transform='rotate(-17 280 100)'>{{method.formateNum(amortizeProfit[1], 2)}}</text>

						</svg>
					</div>
					<img src="../../../assets/img/report/mlm.svg" alt="MLM估值-心流" style="margin: -48px auto 0;display: block;">

					<div class="machineLearningValuationResults specialNumeric">
						<img src="../../../assets/img/report/technologyBox.png" alt="机器学习估值结果-心流">
						<div class="numberBox">
							<span class="valuationMethod">MLM估值：</span>
							<img v-for="(item, index) in machineLearningValuationResults" :key="index + 1"
								:src="require('../../../assets/img/report/'+item.val+'.png')"
								:style="{marginTop:(item.val=='11'?'52px':'0'),width:(item.val=='11'?'5px':'36px')}" alt="估值结果数字-心流">
							<p class="unit" style="font-size: 24px;top: 18px;">
								{{method.technologyNum(pageInfo.relativeValuationValue).unit}}
							</p>
						</div>
					</div>
					<ul class="interpretation">
						<li>
							1、模型基于上市和非上市公司数据，选取合适的估值、统计分析方法标注形成数据集，对估值模型训练。
						</li>
						<li>
							2、心流深度学习估值模型利用神经网络，通过采用梯度下降法最小化损失函数对网络中的权重参数逐层反向调节，通过对训练数据随时间的推移不断学习并提高准确性，利用计算科学智能地计算数据资源价值。
						</li>
					</ul>
					<div class="pagination">{{rangePageNum+7<10?'0'+(rangePageNum+7):rangePageNum+7}}</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-NPCM">
					<p class="broadCategories">六、NPCM估值</p>
					<ul class="revenueContributionInterpretation">
						<li style="text-indent: 2em;">
							NPCM（Net Profit Contribution
							Method，收益贡献法），是指首先将数据资源与其他共同发挥作用的相关资产组成资产组，对资产组的预期收益进行预测，然后剔除有形资产的贡献值，并采用适当的折现率折算成现值，计算出无形资产的贡献值，在无形资产贡献值的基础上乘以数据资源贡献率，计算得出数据资源估值。
						</li>
						<li>
							<img style="width: 168px;margin: 10px 0 5px;" src="../../../assets/img/report/NPCM.png" alt="公式" />
						</li>
						<li>
							<img src="../../../assets/img/report/NPCM.svg" alt="" />
						</li>
					</ul>
					<div class="numberBox">
						<span class="valuationMethod">估值结果：</span>
						<img v-for="(item, index) in revenueContributionResults" :key="index + 1"
							:src="require('@/assets/img/report/'+item.val+'.png')"
							:style="{marginTop:(item.val=='11'?'60px':'0'),width:(item.val=='11'?'5px':'42px')}" alt="估值结果数字-心流">
						<p class="unit" style="font-size: 24px;top: 18px;">
							{{method.technologyNum(pageInfo.absoluteValuationValue).unit}}
						</p>
					</div>
					<div class="contribution1">
						<img src="../../../assets/img/report/contribution1.png" />
					</div>
					<div class="contribution2">
						<ul>
							<li>
								<div></div>
								<p>收益年限</p>
								<img src="../../../assets/img/report/contribution2.png" alt="心流">
							</li>
							<li style="left: -84px;">
								<div></div>
								<p>第i年产生的收益</p>
								<img src="../../../assets/img/report/contribution2.png" alt="心流">
							</li>
							<li style="left: -73px;">
								<div></div>
								<p>无形资产收益</p>
								<img src="../../../assets/img/report/contribution2.png" alt="心流">
							</li>
							<li style="left: -62px;">
								<div></div>
								<p>折现率</p>
								<img src="../../../assets/img/report/contribution2.png" alt="心流">
							</li>
							<li>
								<div></div>
								<p>数据资源贡献率</p>
							</li>
						</ul>
						<img src="../../../assets/img/report/contribution3.png" alt="心流"
							style="position: absolute;top: 28px;left: 240px;">
					</div>
					<div class="pagination">{{rangePageNum+8<10?'0'+(rangePageNum+8):rangePageNum+8}}</div>
				</div>
			</div>

			<!-- 数据资源 -->
			<div class="page" v-for="(page,p) in newDataAsset" :key="p">
				<div class="content page-content" :id="'page-dataAsset'+(p+1)">
					<p class="broadCategories" v-if="p==0">七、数据资源概况</p>

					<div class="dataAssetTable">
						<div v-for="(business,b) in page">
							<p class="broadHeading" v-if="resourceType==1&&business.type=='0'">
								{{'（'+method.numberToChinese(business.seq)+'）'+'业务名称：'+business.businessName}}
							</p>

							<p class="broadHeading" v-if="resourceType==0">
								{{'（'+method.numberToChinese(business.seq)+'）'+'数据资源：'+business.name}}
							</p>


							<div class="businessValuationResults" v-if="business.type=='0'">
								<div class="numberBox dataAssetTechnology">
									<img v-for="(num, index) in dataAssetTechnology[p].number[b].value" :key="index + 1"
										:src="require('../../../assets/img/report/'+num.val+'.png')"
										:style="{marginTop:(num.val=='11'?'52px':'0'),width:(num.val=='11'?'5px':num.val=='12'?'20px':'45px')}"
										alt="估值结果数字-心流">
									<p class="unit" style="font-size: 24px;top: 18px;">
										{{dataAssetTechnology[p].number[b].unit}}
									</p>
								</div>
								<p>估值结果</p>
								<img src="../../../assets/img/report/businessValuationResults.png" alt="业务估值结果">
							</div>

							<div v-if="business.type=='1'">
								<div class="secondaryHeading" v-if="resourceType==1">
									{{business.seq+'、'+'数据资源：'+business.name}}
								</div>

								<p class="text_">
									<i style="font-weight: bold;">数据类型：</i>
									<i v-if="business.resourceType" v-for="(type,d) in JSON.parse(business.resourceType)">
										{{d==JSON.parse(business.resourceType).length-1? type:type+'、'}}
									</i>
								</p>
								<p class="text_">
									<i style="font-weight: bold;">数据资源介绍：</i>
									<i>{{business.describe}}</i>
								</p>

								<div class="dataAsset">
									<p class="units" style="margin-bottom: 5px;">单位：元</p>
									<p class="dataAssetNameSpecialty">
										<span>数据资源名称</span>
										<span>估值基准日账面原值</span>
										<span>估值基准日账面净值</span>
									</p>
									<ul>
										<li>
											<span>{{business.name}}</span>
											<span>{{business.originalValue?method.formateNum(business.originalValue,2):'暂无数据'}}</span>
											<span>{{business.netValue?method.formateNum(business.netValue,2):'1.00（名义价格） '}}</span>
										</li>
									</ul>
								</div>

							</div>
						</div>
					</div>


					<div v-if="!lastPageEven&&p==newDataAsset.length-1">
						<p class="broadCategories">八、营业收入预测</p>
						<div style="position: relative;">
							<img class="revenueForecastingBase" src="../../../assets/img/report/revenueForecastingBase.png" alt="心流">
							<DrawPileBar v-if="charts" :id="'incomeStatement'" :chartData="incomeCharts" />
						</div>
						<p class="units">单位：万元</p>
						<ul class="forecastedTable">
							<i></i>
							<li>
								<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}">项目</span>
								<span v-for="item in incomeForecast.year"
									:style="{'width':100/(incomeForecast.year.length+1)+'%'}">{{item}}</span>
							</li>
							<li v-for="item in forecastfirstPage">
								<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}"
									:class="item.name=='合计'?'total':''">{{item.name}}</span>
								<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}" v-for="(forecas,f) in item.value">
									{{forecas===''?'-':method.formateNum(forecas,2)}}
								</span>
							</li>
							<i></i>
						</ul>
					</div>

					<div class="pagination">{{rangePageNum+9+p}}</div>
				</div>
			</div>

			<!-- 营业收入预测表 -->
			<div class="page" v-if="lastPageEven">
				<div class="content page-content" id="page-incomeForecast" ref="pageIncomeForecast">
					<p class="broadCategories">八、营业收入预测</p>

					<div style="position: relative;">
						<img class="revenueForecastingBase" src="../../../assets/img/report/revenueForecastingBase.png" alt="心流">
						<DrawPileBar v-if="charts" :id="'incomeStatement'" :chartData="incomeCharts" />
					</div>

					<p class="units">单位：万元</p>
					<ul class="forecastedTable">
						<i></i>
						<li>
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}">项目</span>
							<span v-for="item in incomeForecast.year"
								:style="{'width':100/(incomeForecast.year.length+1)+'%'}">{{item}}</span>
						</li>
						<li v-for="item in forecastfirstPage">
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}"
								:class="item.name=='合计'?'total':''">{{item.name}}</span>
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}" v-for="(forecas,f) in item.value">
								{{forecas===''?'-':method.formateNum(forecas,2)}}
							</span>
						</li>
						<i></i>
					</ul>
					<div class="pagination">{{rangePageNum+9+newDataAsset.length}}</div>
				</div>
			</div>
			<!-- 营业收入预测表分页 -->
			<div class="page incomeContinuedTable" v-for="(page,p) in forecastPaging">
				<div class="content page-content" :id="'page-incomeForecast'+(p+1)">
					<p class="units">单位：万元</p>
					<ul class="forecastedTable">
						<i></i>
						<li>
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}">项目</span>
							<span v-for="item in incomeForecast.year"
								:style="{'width':100/(incomeForecast.year.length+1)+'%'}">{{item}}</span>
						</li>
						<li v-for="item in page.data">
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}"
								:class="item.name=='合计'?'total':''">{{item.name}}</span>
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}" v-for="(forecas,f) in item.value">
								{{forecas===''?'-':method.formateNum(forecas,2)}}
							</span>
						</li>
						<i></i>
					</ul>

					<div v-if="!isNextPageNetProfit" style="margin-top: 60px;position: relative;">
						<p class="broadCategories">九、净利润预测</p>

						<img src="../../../assets/img/report/netProfitPedestal.png"
							style="position: absolute;top: 571px;left: 110px;width: 1020px;" />
						<Pile3D :id="'netProfitChart'" :chartData="netProfitCharts" v-if="charts" />


						<div v-if="netProfitNum>0">
							<p class="units">单位：万元</p>
							<ul class="forecastedTable">
								<i></i>
								<li>
									<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}">项目</span>
									<span v-for="item in incomeForecast.year"
										:style="{'width':100/(incomeForecast.year.length+1)+'%'}">{{item}}</span>
								</li>
								<li v-for="item in netProfitfirstPage">
									<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}"
										:class="item.name=='合计'?'total':''">{{item.name}}</span>
									<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}" v-for="(netProfit,f) in item.value">
										{{netProfit===''?'-':method.formateNum(netProfit,2)}}
									</span>
								</li>
								<i></i>
							</ul>
						</div>

					</div>

					<div class="pagination">
						{{lastPageEven?rangePageNum+10+newDataAsset.length+p:rangePageNum+9+newDataAsset.length+p}}
					</div>
				</div>
			</div>


			<div class="page" v-if="isNextPageNetProfit">
				<div class="content page-content" id="page-netProfitForecast">
					<p class="broadCategories">九、净利润预测</p>


					<img src="../../../assets/img/report/netProfitPedestal.png"
						style="position: absolute;top: 686px;left: 110px;width: 1020px;" />
					<Pile3D :id="'netProfitChart'" :chartData="netProfitCharts" v-if="charts&&isNextPageNetProfit" />


					<p class="units">单位：万元</p>
					<ul class="forecastedTable">
						<i></i>
						<li>
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}">项目</span>
							<span v-for="item in incomeForecast.year"
								:style="{'width':100/(incomeForecast.year.length+1)+'%'}">{{item}}</span>
						</li>
						<li v-for="item in netProfitfirstPage">
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}"
								:class="item.name=='合计'?'total':''">{{item.name}}</span>
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}" v-for="(netProfit,f) in item.value">
								{{netProfit===''?'-':method.formateNum(netProfit,2)}}
							</span>
						</li>
						<i></i>
					</ul>

					<div class="pagination">
						{{lastPageEven?rangePageNum+10+newDataAsset.length+forecastPaging.length:rangePageNum+9+newDataAsset.length+forecastPaging.length}}
					</div>
				</div>
			</div>

			<div class="page" v-for="(page,p) in netProfitPaging">
				<div class="content page-content" :id="'page-netProfitForecast'+(p+1)">
					<p class="units">单位：万元</p>
					<ul class="forecastedTable">
						<i></i>
						<li>
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}">项目</span>
							<span v-for="item in incomeForecast.year"
								:style="{'width':100/(incomeForecast.year.length+1)+'%'}">{{item}}</span>
						</li>
						<li v-for="item in page.data">
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}"
								:class="item.name=='合计'?'total':''">{{item.name}}</span>
							<span :style="{'width':100/(incomeForecast.year.length+1)+'%'}" v-for="(netProfit,f) in item.value">
								{{netProfit===''?'-':method.formateNum(netProfit,2)}}
							</span>
						</li>
						<i></i>
					</ul>
					<div class="pagination">
						{{((lastPageEven&&!isNextPageNetProfit)||(!lastPageEven&&isNextPageNetProfit)?10: 11)+rangePageNum+newDataAsset.length+forecastPaging.length+p}}
					</div>
				</div>
			</div>

			<div class="page">
				<div class="content page-content" id="page-hypothesis">
					<p class="broadCategories">十、估值假设</p>
					<p class="broadHeading">（一）本报告采用的估值假设</p>
					<p class="secondaryHeading">1、基本假设</p>
					<p class="secondaryHeadingBrackets">（1）交易假设</p>
					<p class="text threeLevelText">
						交易假设是假定所有委托进行估值的数据资源已经处在交易的过程中，根据委托进行估值的数据资源的交易条件等模拟市场进行估价。交易假设是估值得以进行的一个最基本的前提假设。
					</p>
					<p class="secondaryHeadingBrackets">（2）公开市场假设</p>
					<p class="text threeLevelText">
						公开市场假设，是假定在市场上交易的数据资源，或拟在市场上交易的数据资源，交易双方彼此地位平等，彼此都有获取足够市场信息的机会和时间，以便于对资产的功能、用途及其交易价格等作出理智的判断。公开市场假设以数据资源在市场上可以公开买卖为基础。
					</p>
					<p class="secondaryHeadingBrackets">（3）资产持续使用假设</p>
					<p class="text secondLevelText">资产持续使用假设是指假定委托进行估值的数据资源在估值基准日后仍按照原来的用途、使用方式持续使用下去。</p>
					<p class="secondaryHeading">2、一般假设</p>
					<p class="text">（1）假设估值基准日后国家和地区的政治、经济和社会环境无重大变化；</p>
					<p class="text">（2）假设估值基准日后国家宏观经济政策、产业政策和区域发展政策无重大变化；</p>
					<p class="text">（3）除已知的变化外，假设与被估值单位相关的利率、汇率、赋税基准及税率、政策性征收费用等在估值基准日后不发生重大变化；</p>
					<p class="text">（4）假设估值基准日后被估值单位的管理层是负责的、稳定的，且有能力担当其职务；</p>
					<p class="text">（5）假设被估值单位完全遵守所有相关的法律法规；</p>
					<p class="text secondLevelText">（6）假设估值基准日后无不可抗力对被估值单位造成重大不利影响。</p>
					<p class="secondaryHeading">3、特殊假设</p>
					<p class="text">（1）假设估值基准日后国家现行的有关法律法规及政策、国家宏观经济形势无重大变化，本次交易各方所处地区的政治、经济和社会环境无重大变化；</p>
					<p class="text">（2）假设被估值单位完全遵守所有有关的法律法规；</p>
					<p class="text">（3）假设和被估值单位相关的利率、汇率、赋税基准及税率、政策性征收费用等估值基准日后不发生重大变化；</p>
					<p class="text">（4）假设估值基准日后无其他人力不可抗拒因素及不可预见因素对企业造成重大不利影响；</p>
					<p class="text secondLevelText">（5）假设估值基准日后被估值单位的经营者是负责的，并且公司管理层有能力担当其职务。</p>
					<p class="broadHeading">（二）估值假设对估值结论的影响</p>
					<p class="text">本估值报告估值结论在上述假设条件下在估值基准日时成立，当上述假设条件发生较大变化时，本机构将不承担由于假设条件改变而推导出不同估值结论的责任。</p>


					<div class="pagination">
						{{rangePageNum+9+newDataAsset.length+forecastPaging.length+netProfitPaging.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)}}
					</div>
				</div>
			</div>

			<div class="page">
				<div class="content page-content" id="page-twelve">
					<p class="broadCategories">十一、估值结果</p>

					<div class="valuationResultDV">
						<div class="numberBox">
							<div>
								<div v-for="(item, index) in valueResultNumber" :key="index + 1" class="locationUnit">
									<p class="unit" v-if="item.val==12" style="margin-right: 12px;">
										{{method.technologyNum(pageInfo.minValue).unit}}
									</p>
									<img :src="require('../../../assets/img/report/'+item.val+'.png')"
										:style="{marginTop:item.top,width:(item.val=='11'?'5px':item.val=='12'?'20px':'46px')}"
										alt="估值结果数字-心流">
								</div>
								<p class="unit">{{method.technologyNum(pageInfo.maxValue).unit}}</p>
							</div>

						</div>
					</div>
					<img src="../../../assets/img/report/valuationResultSpecialty.png">
					<div class="pagination">
						{{rangePageNum+10+newDataAsset.length+forecastPaging.length+netProfitPaging.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)}}
					</div>
				</div>
			</div>

			<div class="page" v-for="(page, pageIndex) in pagedItems" v-if="initStatus">
				<div class="content page-content" :id="'page-dataResourceResult'+pageIndex">
					<div class="resourceResultList">
						<div v-if="resourceType==1" class="multiserviceLine">
							<p class="resourceHead">
								<img src="../../../assets/img/report/resourceHead.png" />
								<span>序号</span>
								<span>业务类型</span>
								<span>数据资源名称</span>
								<span>账面净值（元）</span>
								<span>估值结果（元）</span>
							</p>
							<table border="0" style="border-collapse: collapse;">
								<tbody>
									<template v-for="(item, index) in page">
										<tr v-if="item.resourceName=='resourceSum'" class="summation">
											<td colspan="3">合计</td>
											<td class="amountPattern">{{method.formateNum(item.netValue,2) }}</td>
											<td class="amountPattern">
												{{setTenThousand(item.valuation.split('-')[0])+'~'+setTenThousand(item.valuation.split('-')[1])}}
											</td>
										</tr>
										<tr v-else-if="item.category=='productSum'" class="aggregate">
											<td colspan="3">总计</td>
											<td class="amountPattern">{{method.formateNum(item.netValue,2) }}</td>
											<td class="amountPattern">
												{{setTenThousand(item.valuation.split('-')[0])+'~'+setTenThousand(item.valuation.split('-')[1])}}
											</td>
										</tr>
										<tr v-else>
											<td :rowspan="getPageLength(pageIndex, item.category,'pagedItems')"
												v-if="index===0 || item.category !== page[index - 1].category">
												{{item.seq}}
											</td>
											<td :rowspan="getPageLength(pageIndex, item.category,'pagedItems')"
												v-if="(index===0 || item.category !== page[index - 1].category)&&item.resourceName!='resourceSum'">
												{{ item.category }}
											</td>

											<td>{{item.resourceName}}</td>
											<td class="amountPattern">{{item.netValue==0?'1.00（名义价格）': method.formateNum(item.netValue,2) }}
											</td>

											<td class="amountPattern" :rowspan="getPageLength(pageIndex, item.category,'pagedItems')"
												v-if="item.isExist && (index === 0 || item.category !== page[index - 1].category)">
												{{setTenThousand(item.businessValuation.split('-')[0])+'~'+setTenThousand(item.businessValuation.split('-')[1])}}
											</td>

											<td v-if="!item.isExist" class="amountPattern">
												{{setTenThousand(item.value.split('-')[0])+'~'+setTenThousand(item.value.split('-')[1])}}
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
						<div v-else class="singleLine">
							<p class="resourceHead">
								<img src="../../../assets/img/report/resourceHead.png" />
								<span>数据资源名称</span>
								<span>账面净值（元）</span>
								<span>估值结果（元）</span>
							</p>

							<table border="0" style="border-collapse: collapse;">
								<tbody>
									<template v-for="(item, index) in page">
										<tr v-if="item.resourceName=='resourceSum'" class="aggregate">
											<td>合计</td>
											<td>{{method.formateNum(item.netValue,2) }}</td>
											<td>
												{{setTenThousand(item.valuation.split('-')[0])+'~'+setTenThousand(item.valuation.split('-')[1])}}
											</td>
										</tr>
										<tr v-else>
											<td>{{item.resourceName}}</td>
											<td>{{item.netValue==0?'1.00（名义价格）': method.formateNum(item.netValue,2) }}</td>

											<td :rowspan="getPageLength(pageIndex, item.category,'pagedItems')"
												v-if="item.isExist && (index === 0 || item.category !== page[index - 1].category)">
												{{setTenThousand(pageInfo.minValue)+'~'+setTenThousand(pageInfo.maxValue)}}
											</td>

											<td v-if="!item.isExist">
												{{setTenThousand(item.value.split('-')[0])+'~'+setTenThousand(item.value.split('-')[1])}}
											</td>
										</tr>

									</template>
								</tbody>
							</table>
						</div>
					</div>


					<div v-if="pageIndex==pagedItems.length-1&&!resourceResultFlag" style="margin-top: 60px;">
						<p class="broadCategories">十二、特别事项说明和瑕疵事项</p>
						<p class="text">特别事项可能对估值结论产生影响，估值报告使用者应予以关注。</p>
						<p class="text">
							（一）在本报告所述估值目的下对估值对象进行价值测算，并不涉及到对该估值目的所对应的估值购买行为做出任何判断。估值工作很大程度上，依赖于被估值企业提供的有关资料。因此，估值工作是以被估值企业提供的财务数据、预测财务信息及审核人员核实信息时企业答复的真实、合法为前提。
						</p>
						<p class="text stairText">
							（二）本报告仅为{{pageInfo.baseInfo.companyName}}提供内部参考，不应当被认为是对估值企业可实现价格的保证。如果在实际交易中产生纠纷，北京心流慧估科技有限公司不承担任何法律责任。
						</p>
						<p class="broadCategories">十三、估值报告使用限制说明</p>
						<p class="text">（一）估值报告的估值结论仅为本报告所列明的估值目的和用途而服务。</p>
						<p class="text">（二）除非事前征得估值机构书面同意，估值报告的内容不得被摘抄、引用或披露于公开媒体，法律、法规规定以及相关当事方另有约定的除外。</p>
						<p class="text">（三）本报告必须完整使用方为有效，对仅使用报告中部分内容导致的可能的损失，本公司不承担责任。</p>
						<p class="text stairText">（四）估值报告使用者应当正确理解估值结论。估值结论不等同于估值对象可实现价格，估值结论不应当被认为是对估值对象可实现价格的保证。</p>
						<p class="broadCategories">十四、估值报告解释权</p>
						<p class="text">本估值报告意思表达解释权属于出具报告的估值机构，除国家法律、法规有明确的特殊规定外，其他任何单位和部门均无权做出书面解释。</p>

					</div>


					<div class="pagination">
						{{rangePageNum+11+newDataAsset.length+forecastPaging.length+netProfitPaging.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+pageIndex}}
					</div>
				</div>
			</div>

			<div class="page" v-if="initStatus&&resourceResultFlag">
				<div class="content page-content" id="page-specialEvent">
					<p class="broadCategories">十二、特别事项说明和瑕疵事项</p>
					<p class="text">特别事项可能对估值结论产生影响，估值报告使用者应予以关注。</p>
					<p class="text">
						（一）在本报告所述估值目的下对估值对象进行价值测算，并不涉及到对该估值目的所对应的估值购买行为做出任何判断。估值工作很大程度上，依赖于被估值企业提供的有关资料。因此，估值工作是以被估值企业提供的财务数据、预测财务信息及审核人员核实信息时企业答复的真实、合法为前提。
					</p>
					<p class="text stairText">
						（二）本报告仅为{{pageInfo.baseInfo.companyName}}提供内部参考，不应当被认为是对估值企业可实现价格的保证。如果在实际交易中产生纠纷，北京心流慧估科技有限公司不承担任何法律责任。
					</p>
					<p class="broadCategories">十三、估值报告使用限制说明</p>
					<p class="text">（一）估值报告的估值结论仅为本报告所列明的估值目的和用途而服务。</p>
					<p class="text">（二）除非事前征得估值机构书面同意，估值报告的内容不得被摘抄、引用或披露于公开媒体，法律、法规规定以及相关当事方另有约定的除外。</p>
					<p class="text">（三）本报告必须完整使用方为有效，对仅使用报告中部分内容导致的可能的损失，本公司不承担责任。</p>
					<p class="text stairText">（四）估值报告使用者应当正确理解估值结论。估值结论不等同于估值对象可实现价格，估值结论不应当被认为是对估值对象可实现价格的保证。</p>
					<p class="broadCategories">十四、估值报告解释权</p>
					<p class="text">本估值报告意思表达解释权属于出具报告的估值机构，除国家法律、法规有明确的特殊规定外，其他任何单位和部门均无权做出书面解释。</p>

					<div class="pagination">
						{{rangePageNum+11+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)}}
					</div>
				</div>
			</div>

			<div class="page">
				<div class="content page-content" id="page-accessory">
					<p class="broadCategories">十五、附件</p>
					<p class="accessoryTitle">
						<i>附件1</i>
						<i>{{pageInfo.baseInfo.companyName}}资产负债表</i>
						<i>单位：元</i>
					</p>

					<LibTableSpecialty1 :liaTableInfo="liabilities" v-if="initStatus" :year="pageInfo.liaTableInfo.year"
						:versions="0" />

					<div class="pagination">
						{{rangePageNum+11+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
					</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-libTableContinuedTable">
					<p class="accessoryTitle">
						<i>附件1</i>
						<i>{{pageInfo.baseInfo.companyName}}资产负债表（续表）</i>
						<i>单位：元</i>
					</p>
					<LibTableSpecialty2 :liaTableInfo="liabilities" v-if="initStatus" :year="pageInfo.liaTableInfo.year"
						:versions="0" />
					<div class="pagination">
						{{rangePageNum+12+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
					</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-profit">
					<p class="accessoryTitle">
						<i>附件1</i>
						<i>{{pageInfo.baseInfo.companyName}}利润表</i>
						<i>单位：元</i>
					</p>

					<ProfitTableSpecialty :profitTableInfo="profitTableInfo" v-if="initStatus" :year="profitTableInfo.year"
						:versions="0" />

					<div class="pagination">
						{{rangePageNum+13+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
					</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-profit2">
					<p class="accessoryTitle">
						<i>附件1</i>
						<i>{{pageInfo.baseInfo.companyName}}利润表（续表）</i>
						<i>单位：元</i>
					</p>

					<ProfitTableSpecialty2 :profitTableInfo="profitTableInfo" v-if="initStatus" :year="profitTableInfo.year"
						:versions="0" />

					<p class="accessoryTitle" style="margin-top: 60px;">
						<i>附件1</i>
						<i>{{pageInfo.baseInfo.companyName}}财务比率表</i>
						<i></i>
					</p>

					<FinancialRatios :financeRatio="financeRatio" v-if="initStatus" :year="financeRatio.year" :versions="0" />

					<div class="pagination">
						{{rangePageNum+14+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
					</div>
				</div>
			</div>

			<!-- <div class="page" v-if="channel">
				<div class="content page-content" id="page-stock">
					<img class="introduce-img" :src="pageInfo.introImage" alt="公司简介" />
					<div class="pagination">{{pagesNumber.length+12}}</div>
				</div>
			</div> -->

			<div class="page">
				<div class="content page-content" id="page-MLMIntroduction">
					<p class="accessoryTitle">
						<i>附件2</i>
						<i>MLM估值介绍</i>
						<i></i>
					</p>

					<div class="MLMIntroduction">
						<p>
							机器学习估值法是指采用集成学习算法、长短期记忆网络、对比学习和知识图谱等技术，结合8项诺贝尔经济学奖成果和先进的金融工程实践，从多个角度对股权及数据资源价值进行量化。现仅以集成学习算法为例概要说明机器学习方法的估值原理。
						</p>
						<p>
							首先，利用变分推断对公司的财务报表数据进行学习，以提取关键特征和因子变量，将数据映射到潜在空间中，并通过潜在变量的分布来描述数据的特征，从而实现数据的降维和特征提取。这为后续的估值提供了有用的信息，减少了对人工特征工程的依赖，提高了模型的泛化能力。
						</p>
						<p>
							采集A股、纳斯达克和港股历史年度的上市公司三大财务报表数据和资产定价因子数据，包括国债利率、74个行业部门虚拟变量、成长因子（营收增长率、净利润增长率等）、财务杠杆因子（负债比率、权益比率、长期负债比率等）、动量因子（现金流动量因子、销售额动量因子等）以及其他资产的定价因子和NE(净利润)/BVE(净资产)、NE(净利润)/Rev(营业收入)等指标，并对其进行清理和筛选，以处理可能存在的错误、缺失值或异常值。这包括检查和修复财务报表中的数据错误，排除不完整或不准确的信息。对于数据缺失值采用同一资产数据的前向值或后向值进行填充。对于极值使用3σ原则，计算数据的均值和标准差，确定数据的上下限，然后对超出上下限的数据进行剔除。
						</p>
						<p>变分推断模型由编码器和解码器两部分组成。编码器将输入数据映射到潜在空间中的概率分布，而解码器则将潜在空间中的样本映射回原始数据空间，从而实现数据的重构和特征提取。</p>
						<p>
							编码器将输入数据x映射到潜在空间中的概率分布 q(z|x)，其中 z 是潜在变量（latent variable）。假设潜在空间的分布是高斯分布，参数化为均值 μ 和方差σ2。编码器的输出为潜在空间中的均值向量
							μ 和方差向量σ2，用于描述输入数据在潜在空间中的分布。
						</p>
						<p style="font-weight: bold;">编码器的表达式如下：μ,logσ2=Encoder(x)</p>
						<p>解码器也由多层神经网络组成，其结构与编码器相似。解码器的目标是将潜在空间中的样本映射回原始数据空间，重构输入数据。</p>
						<p>
							得到了这些潜在的因子变量之后，可以将它们作为集成算法的输入数据，结合其他市场因子、行业因子等信息，用于估值的预测。集成算法可以综合考虑多个因子对估值的影响，并通过组合多个模型的预测结果来提高估值的准确性和鲁棒性。
						</p>
						<p>集成算法中设决策树的输出为 Yi ，对于输入样本 Xi
							，可以通过从树的根节点开始逐步遍历到叶子节点，根据节点的特征和阈值进行划分，最终得到叶子节点的输出值。使用预测误差作为特征选择的依据。这里采用平方差作为误差的评价，即</p>
						<img style="width: 182px;" src="../../../assets/img/report/MILFormula.png" alt="" />
						<p>这个过程可以用以下公式表示：</p>
						<img src="../../../assets/img/report/MILFormula2.png" alt="" />
						<p>其中：m是叶子节点的数量。</p>
						<p class="secondIndent">Rm是第 m个叶子节点的区域， I(⋅) 是指示函数，表示样本 Xi​ 是否属于区域 Rm。</p>
						<p class="secondIndent">cm是第 m个叶子节点的输出值。</p>
						<img src="../../../assets/img/report/MILFormula3.png" alt="" />
						<p>通过集成算法，训练机器学习模型得到不同企业特征对估值因子的影响，得到估值因子的预测模型。</p>
						<p>其次，采用混合专家模型等技术，来综合考虑多个模型的预测结果。这些算法能够有效降低模型的方差，提高模型的鲁棒性和预测能力。通过集成多个模型的预测结果，从而能够更全面地评估股权的价值，提高估值结果的准确性和可靠性。
						</p>
					</div>
					<div class="pagination">
						{{rangePageNum+15+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
					</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-formula">
					<p class="accessoryTitle">
						<i>附件3</i>
						<i>计算公式</i>
						<i></i>
					</p>
					<div class="formula-container">
						<h3>1、财务指标公式</h3>
						<p>
							<span>◆</span>
							<span>资产负债率=负债合计/资产总计×100%</span>
						</p>
						<p>
							<span>◆</span>
							<span>流动比率=流动资产合计/流动负债合计</span>
						</p>
						<p>
							<span>◆</span>
							<span>EBIT利息保障倍数=EBIT/利息费用</span>
						</p>
						<p>
							<span>◆</span>
							<span>毛利率=（营业收入-营业成本）/营业收入×100%</span>
						</p>
						<p>
							<span>◆</span>
							<span>净利润率=净利润/营业收入×100%</span>
						</p>
						<p>
							<span>◆</span>
							<span>主营业务收入增长率=（本年度营业收入-上年度营业收入）/上年度营业收入×100%</span>
						</p>
						<p>
							<span>◆</span>
							<span>净资产收益率=净利润×2/（本年度期初净资产+本年度期末净资产）×100%</span>
						</p>
						<p>
							<span>◆</span>
							<span>应收账款周转率=营业收入/平均应收账款</span>
						</p>
						<p>
							<span>◆</span>
							<span>存货周转率=营业成本/平均存货</span>
						</p>
					</div>
					<div class="formula-container">
						<h3>2、估值方法公式</h3>
						<p>
							<span>◆</span>
							<span>流动性折价率（BSM模型）</span>
						</p>
						<img style="width: 274px;" src="../../../assets/img/report/valuationFormula.png" alt="" />
						<p>
							<span></span>
							<span>其中：P：卖方期权价值</span>
						</p>
						<p class="thereinto">S：标的资产当前价值</p>
						<p class="thereinto">X：期权约定价值（执行价格）</p>
						<p class="thereinto">T：期权合约到期时间（单位：年）</p>
						<p class="thereinto">r： 无风险利率</p>
						<p class="thereinto">σ：股票收益率的年化波动率</p>
						<p>
							<span>◆</span>
							<span>无形资产现金流=净利润+税后利息支出+折旧及摊销-资本性支出</span>
						</p>
						<p>
							<span>◆</span>
							<span style="font-size: 18px;color: #282828;">WACC（加权平均资本成本）计算公式：</span>
							<span
								style="padding-left: 14px;margin-top: 4px;line-height: 38px;">WACC=（债务/总资本）×债务成本×（1-企业所得税税率）+（资产净值/总资本）×股权成本。系统深入研究A股、新三板等板块市场风险、行业风险，整理统计出行业资本结构、股权成本、债权成本等，系统将根据企业所填信息，自动选取对应行业WACC数值。</span>
						</p>
						<p>
							<span>◆</span>
							<span>无形资产折现率r = WACC+无形资产特别风险溢价</span>
						</p>
					</div>
					<div class="pagination">
						{{rangePageNum+16+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
					</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-references">
					<p class="accessoryTitle" style="margin-top: 67px;margin-bottom: 33px;">
						<i>附件4</i>
						<i>参考文件</i>
						<i></i>
					</p>
					<div class="references">
						<p>1、《企业会计准则》</p>
						<p>2、《企业数据资源相关会计处理暂行规定》 （财会〔2023〕11号）</p>
						<p>3、《中小微企业估值规范》（T/CECC 013-2021）</p>
						<p>4、《私募投资基金非上市股权投资估值指引》</p>
						<p>5、《数据资产评估指导意见》（中评协〔2023〕17 号）</p>
						<p>6、《中共中央、国务院关于构建数据基础制度更好发挥数据要素作用的意见》</p>
						<p>7、《国民经济行业分类》（GB/T 4754—2017）</p>
						<p>8、《数字经济及其核心产业统计分类（2021）》（国家统计局令第33号）</p>
						<p>9、《企业绩效评价标准值》</p>
					</div>
					<div class="pagination">
						{{rangePageNum+17+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
					</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-statement">
					<ul>
						<li>
							<div> <i></i>
								<p>估值声明</p>
								<p>
									本报告由系统自动生成。该系统依据企业提供的财务数据和软性指标，结合具备可靠性水平的数据库，根据通用的行业业绩及财务预测逻辑和规则，对企业估值所需要的必要参数进行预测和估算，企业提供资料的完整度和准确性对最终估值结果有重要影响。请本报告使用者知悉。
								</p>
								<i></i>
							</div>
						</li>
						<li>
							<div> <i></i>
								<p>免责声明</p>
								<p>
									系统所采用的数据信息来源于企业自行提供的资料和公开渠道可获取的行业信息和数据，并基于前述资料属实、合法的假设出具本报告，网站亦不对前述资料的准确性、完整性负有实质审核的义务，本报告仅作为对数据资源价值衡量的参考，非抵押、转让等交易的直接证据，网站及其运营方不承担因信赖本报告信息而采取行动所导致的任何损失及一切法律责任。
								</p>
								<i></i>
							</div>
						</li>
						<li>
							<div> <i></i>
								<p>心流慧估</p>
								<p>
									北京心流数据科技有限公司（简称“心流数据”）是一家行业领先的数字投行 (DiBanking)，专注于数据变现生态建设，为客户提供“估值+组合式金融服务”。
									现为文化科技创新服务联盟金融专委会主任委员单位。
								</p>
								<i></i>
							</div>
						</li>
					</ul>

					<div class="pagination">
						{{rangePageNum+18+newDataAsset.length+forecastPaging.length+netProfitPaging.length+pagedItems.length+(lastPageEven?1:0)+(isNextPageNetProfit?1:0)+(resourceResultFlag?1:0)}}
					</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-flow">
					<img src="../../../assets/img/logo/flowDataBlack.png" alt="公司logo-心流" style="width: 285px;margin-top: 377px;">
					<p class="ourCompany">北京心流慧估科技有限公司</p>
					<p>联系方式</p>
					<ul class="contact">
						<li> <span>公司地址：</span> <span>北京市朝阳区酒仙桥路甲12号电子城科技大厦15层1505</span> </li>
						<li> <span>联系电话：</span> <span>010-87235120</span> </li>
						<li> <span>服务热线：</span> <span>17610076778</span> </li>
						<li> <span><i>网</i><i>址：</i></span> <span>www.flcccc.com</span> </li>
					</ul>
					<ul class="flowQRCode">
						<li>
							<div> <img src="../../../assets/img/report/tencent.jpg" alt="公众号"> </div>
							<p>扫描二维码</p>
							<p>关注心流慧估公众号</p>
						</li>
						<li>
							<div> <img src="../../../assets/img/report/miniProgram.jpg" alt="小程序"> </div>
							<p>扫描二维码</p>
							<p>关注心流慧估小程序</p>
						</li>
						<li>
							<div> <img src="../../../assets/img/report/officialCustomerService.jpg" alt="官方客服"> </div>
							<p>扫描二维码</p>
							<p>关注心流慧估官方客服</p>
						</li>
					</ul>

				</div>
			</div>



		</div>


		<div id="loading" style="display: none;">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import '@/assets/css/loading.css'
	import common from '@/views/common'
	import PdfOnload from '@/utils/pdf'
	import Draw3DPieCanvas from '@/common/echarts/draw3DPieCanvas.vue'
	import Histogram from '@/common/echarts/histogram.vue'
	import LibTableSpecialty1 from '@/components/reportComponent/LibTableSpecialty1.vue'
	import LibTableSpecialty2 from '@/components/reportComponent/LibTableSpecialty2.vue'
	import ProfitTableSpecialty from '@/components/reportComponent/profitTableSpecialty.vue'
	import ProfitTableSpecialty2 from '@/components/reportComponent/profitTableSpecialty2.vue'
	import FinancialRatios from '@/components/reportComponent/financialRatios.vue'

	import DrawPileBar from '@/common/echarts/drawPileBar.vue'
	import Pile3D from '@/common/echarts/pile3D.vue'


	export default ({
		mixins: [common],
		components: {
			Draw3DPieCanvas,
			Histogram,
			LibTableSpecialty1,
			LibTableSpecialty2,
			ProfitTableSpecialty,
			ProfitTableSpecialty2,
			FinancialRatios,
			DrawPileBar,
			Pile3D

		},
		data() {
			return {
				reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
				channel: this.$route.query.channel ? this.$route.query.channel : '',
				pdfArr: [],
				pdfIndex: 0,
				pageInfo: {
					isTestAccount: null, //0:无水印可下载，1：有水印不可下载
					createTime: 'xxxx.xx.xx',
					reportBaseDate: '',
					baseInfo: {
						companyName: '',
						establishDate: '',
					},
					reportNumber: '',
					year: [],
					absoluteValuation: {
						ggm: ''
					},
					industry: '',
					firstPageImage: '', //合作公司的logo
					introImage: '', //合作公司的简介
					liaTableInfo: {},
				},
				valueResultNumber: [], //估值结果的数字
				question: [{
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}],
				financial: [],
				boxhead: ['项目', 'xxxx年预测(万元)'],
				income: ['公司营业收入', 0],
				netAsset: ['公司净资产', 0],
				netProfit: ['公司净利润', 0],
				amortizeProfit: ['公司息税折旧摊销前利润', 0],
				profitDataYearX: ['125', '260'],
				profitDataIncomeX: ['130', '305'],
				profitDataNetAssetX: ['120', '302'],
				profitDataNetAssetY: ['184', '182'],
				profitDataNetProfitX: ['110', '295'],
				machineLearningValuationResults: [], //机器学习估值法结果特殊数字(ML估值法)
				revenueContributionResults: [], //收入贡献法估值
				dataType: '', //数据类型
				portfolio: [],
				netBookValue: [], //账面净值
				summation: 0, //账面净值合计
				netBookValuePaging: [],
				pagesNumber: [],
				liabilities: {
					monetaryCapital: [],
					tradingAssets: [],
					derivativeAssets: [],
					billReceivable: [],
					receivables: [],
					accountReceivable: [],
					prepayment: [],
					otherReceivables: [],
					inventory: [],
					contractualAssets: [],
					saleForAssets: [],
					illiquidAssetsOne: [],
					otherCurrentAssets: [],
					totalCurrentAssets: [],
					debtInvestment: [],
					otherDebtInvestment: [],
					longAeceivables: [],
					longInvestment: [],
					otherEquityInvestment: [],
					otherIlliquidAssets: [],
					investmentProperty: [],
					fixedAssets: [],
					constructionProjects: [],
					productiveAsset: [],
					oilAndGasAssets: [],
					rightAssets: [],
					netWorth: [],
					developExpense: [],
					goodwill: [],
					longExpenses: [],
					deferredAssets: [],
					otherNonCurrentAssets: [],
					totalNonCurrentAssets: [],
					totalAssets: [],
					shortLoan: [],
					transactionLiabilities: [],
					derivativeLiabilities: [],
					notesPayable: [],
					accountsPayable: [],
					advanceReceipt: [],
					contractualLiabilities: [],
					payrollPayable: [],
					taxPayable: [],
					otherPayables: [],
					saleForLiabilities: [],
					illiquidLiabilitiesOne: [],
					otherNonLiabilities: [],
					totalNonLiabilities: [],
					longLoan: [],
					bondsPayable: [],
					bondsPreferredStock: [],
					bondsPerpetuaDebt: [],
					leaseLiabilities: [],
					longPayment: [],
					estimatedLiabilities: [],
					deferredIncome: [],
					deferredLiability: [],
					otherFixedLiabilities: [],
					totalFixedLiabilities: [],
					totalLiabilities: [],
					paidUpCapital: [],
					otherEquity: [],
					equityPreferredStock: [],
					equityPerpetuaDebt: [],
					capitalReserve: [],
					treasuryShare: [],
					otherIncome: [],
					appropriativeReserve: [],
					earnedSurplus: [],
					undistributedProfit: [],
					totalParentEquity: [],
					minorityEquity: [],
					totalOwnershipInterest: [],
					totalLiabilitiesEquity: []

				},
				profitTableInfo: {
					income: [],
					cost: [],
					taxAdditional: [],
					saleCost: [],
					manageCost: [],
					researchCost: [],
					financeCost: [],
					interestCost: [],
					interestIncome: [],
					otherBenefits: [],
					investmentIncome: [],
					enterpriseIncome: [],
					financialAssetsBenefits: [],
					netExposureHedgeIncome: [],
					fairValueIncome: [],
					creditImpairmentLoss: [],
					assetLoss: [],
					assetDisposalIncome: [],
					businessProfit: [],
					outsideIncome: [],
					outsideExpenditure: [],
					totalProfit: [],
					incomeTax: [],
					netProfit: [],
					goingConcernNetProfit: [],
					discontinuedOperationsNetProfit: [],
					parentCompanyNetProfit: [],
					minorityStockLegalRight: [],
					totalComprehensiveIncome: [],
					parentCompanyTci: [],
					minorityStockTci: [],
					ociNetTax: [],
					parentCompanyOciNetTax: [],
					notReclassifiedOci: [],
					remeasureBenefitAmount: [],
					notTransferOci: [],
					otherEquityFairValue: [],
					enterpriseCreditRiskFairValue: [],
					reclassifiedOci: [],
					transferOci: [],
					otherClaimsFairValue: [],
					financialAssetsOciAmount: [],
					otherClaimsCreditImpairment: [],
					cashFlowHedgingReserve: [],
					foreignCurrencyBalance: [],
					minorityStockOciNetTax: [],
					basicEps: [],
					dilutedEps: []

				},
				financeRatio: {
					liabilitiesRatio: ['', ''],
					curAssetsRatio: ['', ''],
					ebitMulRatio: ['', ''],
					grossProfitRatio: ['', ''],
					netProfitRatio: ['', ''],
					incomeRatio: ['', ''],
					netAssetsRatio: ['', ''],
					receivableRatio: ['', ''],
					inventoryRatio: ['', '']
				},
				resourceType: 0, //单一：0   多种：1
				incomeForecast: {
					data: [],
					year: []
				},
				incomeCharts: {
					data: [],
					year: []
				},
				forecastfirstPage: [], //营业收入预测第一页数据
				dataAssetForecast: [], //数据资源页面中营业收入预测的第一个表
				forecastPaging: [], //营业收入预测分页数据
				netProfitfirstPage: [], //净利润预测第一页
				netProfitPaging: [], //净利润预测分页数据
				netProfitForecast: {
					data: [],
					year: []
				},
				netProfitCharts: {
					data: [],
					year: []
				},
				lastPageEven: null, //数据资源最后一页的内容是否是偶数个
				dataAssetTechnology: [],
				dataAsset: [], //数据资源
				newDataAsset: [], //数据资源分页后的数据
				productSum: [{ //数据资源合计
					value: ''
				}, {
					value: ''
				}, {
					value: ''
				}],
				isDataAssetTotal: false, //是否新开一页展示合计  true：新开页    false:不新开页
				totalResources: 0, //数据资源最后一页的总个数
				isNextPageNetProfit: true, //是否新开一页显示净利润的echarts图   true :是   false：否
				netProfitNum: 0,
				dataResourceTable: [],
				itemsPerPage: 25,
				firstPageItems: 19, // 第一页显示的小类数量
				subsequentPagesItems: 26, // 后续每页显示的小类数量
				rangePageNum: '', //算出估值范围的页数，按此来计算往下的页数
				resourceOutcome: [],
				resourceResultFlag: true //是否新开一页显示特别事项说明   true :是   false：否


			}
		},
		computed: {
			initStatus: function() {
				if (this.pageInfo.baseInfo.companyName) {
					return true;
				} else {
					return false;
				}
			},
			charts() {
				if (this.incomeForecast.data.length > 0) {
					return true;
				} else {
					return false;
				}
			},
			allCategoryItems() {
				return this.resourceOutcome.flatMap(item => {
					const resources = item.dataResources;
					return resources.map(resource => ({
						category: item.businessName,
						businessValuation: item.valuation,
						seq: item.seq,
						isExist: item.isExist,
						...resource
					}));

				});
			},
			pagedItems() { //按照指定页数分页，固定每页几个数据
				const pages = [];
				let currentPage = [];
				let currentCount = 0;

				for (let i = 0; i < this.allCategoryItems.length; i++) {
					const item = this.allCategoryItems[i];
					if (currentCount >= this.itemsPerPage) {
						pages.push(currentPage);
						currentPage = [item];
						currentCount = 1;
					} else {
						currentPage.push(item);
						currentCount++;
					}

					// 如果这是最后一个元素，则添加剩余的项目到页面
					if (i === this.allCategoryItems.length - 1 && currentPage.length > 0) {
						pages.push(currentPage);
					}
				}

				return pages;
			},
			allItemsWithCategory() { //过滤businessName==productSum的数据
				return this.dataResourceTable.flatMap(item => {
					if (item.businessName !== 'productSum') {
						try {
							// 尝试解析 dataResources 字段
							const resources = JSON.parse(item.dataResources);
							// const resources = item.dataResources;
							// 确保 resources 是一个数组
							if (Array.isArray(resources)) {
								return resources.map(resource => ({
									category: item.businessName,
									valuation: item.valuation,
									seq: item.seq,
									...resource
								}));
							} else {
								console.warn(`dataResources of ${item.businessName} is not an array`);
								return []; // 如果不是数组，则返回空数组
							}
						} catch (error) {
							console.error(`Failed to parse dataResources for ${item.businessName}:`, error);
							return []; // 解析失败时返回空数组
						}
					}
					return []; // 对于 businessName 为 'productSum' 的项，返回空数组
				});
			},
			multipleRulesPaged() { //多规则分页，第一页几个，剩下的数据固定几个分页
				const pages = [];
				const allItems = this.allItemsWithCategory;

				// 处理第一页
				const firstPage = allItems.slice(0, this.firstPageItems);
				if (firstPage.length) {
					pages.push(firstPage);
				}

				// 处理后续页面
				for (let i = this.firstPageItems; i < allItems.length; i += this.subsequentPagesItems) {
					const nextPage = allItems.slice(i, i + this.subsequentPagesItems);
					if (nextPage.length) {
						pages.push(nextPage);
					}
				}

				return pages;
			},



		},
		mounted() {
			this.init()
		},
		methods: {
			getPageLength(pageIndex, category, key) {
				const page = this[key][pageIndex];
				return page.filter(item => item.category === category && item.resourceName != 'resourceSum').length;
			},
			getPageLength2(pageIndex, category, key) {
				const page = this[key][pageIndex];
				return page.filter(item => item.category === category).length;
			},
			isNumber(value) {
				return typeof value === 'number';
			},
			setTenThousand(value) {
				var data = this.method.formateNum(value * 10000)
				return data
			},
			normalizeArrayLengths(originalArray, valueLength) {
				const data = originalArray.slice();

				// 确保data是一个数组且非空  
				if (!Array.isArray(data) || data.length === 0) {
					return data;
				}

				// 遍历数组中的每个元素  
				for (let i = 0; i < data.length; i++) {
					// 获取当前元素的value数组  
					let currentValue = data[i].value;

					// 如果当前value数组长度小于第一个元素的value数组长度  
					if (currentValue.length < valueLength) {
						// 计算需要补充的空字符串数量  
						const diff = valueLength - currentValue.length;

						// 创建一个新的数组，包含原数组的值和足够数量的空字符串  
						currentValue = currentValue.concat(Array(diff).fill(""));

						// 更新当前元素的value属性  
						data[i].value = currentValue;
					}
				}

				// 返回调整后的数组  
				return data;
			},
			includeData(arry, valueToFind2) {
				return arry.some(item => item.type === valueToFind2)
			},
			paginateData(data_assets) {
				let result = [];
				let currentGroup = null;

				data_assets.forEach(item => {
					if (item.type === 0) { // 遇到新的业务对象，创建新的子数组
						if (currentGroup) {
							if (currentGroup.length > 1) {
								currentGroup = [item];
								result.push(currentGroup);
							} else {
								currentGroup.push(item)
								currentGroup = null
							}

						} else {
							currentGroup = [item];
							result.push(currentGroup);
						}

					} else if (item.type === 1) { // 添加资产对象到当前子数组
						if (currentGroup !== null) {
							if (this.includeData(currentGroup, 0)) {
								currentGroup.push(item);
								currentGroup = null
							} else {
								currentGroup.push(item);
							}
						} else {
							currentGroup = [item]
							result.push(currentGroup);
						}
					}
				});

				return result;
			},
			convertData(obj) {
				const newData = JSON.parse(JSON.stringify(obj));;
				return newData
			},
			setNetValueTotal(data, keyName) { //计算净值合计
				let total = 0
				for (var i = 0; i < data.length; i++) {
					total += data[i][keyName] == 0 ? 1 : Number(data[i][keyName])
				}

				console.log('合计--------------------', total)

				return total
			},
			isExistence(data, keyName) { //判定净值是否为0
				let flag = false
				for (var i = 0; i < data.length; i++) {
					if (data[i][keyName] == 0) {
						flag = true
						break;
					}
				}
				return flag
			},
			init() {
				var that = this;
				this.$post(this.domain.valuationProject + 'valuationReport/qryDataValuationReport', qs.stringify({
						reportId: this.reportId,
						channel: this.channel
					}))
					.then((res) => {
						var data = res.data;
						console.log(data)
						if (data.code == 200 && data.content) {
							that.pageInfo = data.content;


							this.dataResourceTable = JSON.parse(that.pageInfo.dataResource).dataResourcesList

							console.log('www-----', JSON.parse(that.pageInfo.dataResource))

							console.log('多规则分页--------', this.multipleRulesPaged)

							if ((this.multipleRulesPaged.length > 1 && this.multipleRulesPaged[this.multipleRulesPaged.length - 1]
									.length == 26) || this.multipleRulesPaged.length == 1 && this.multipleRulesPaged[0].length == 19) {
								this.rangePageNum = 2 + this.multipleRulesPaged.length
							} else if (this.multipleRulesPaged.length == 1) {
								this.rangePageNum = 2
							} else if (this.multipleRulesPaged.length > 1) {
								this.rangePageNum = this.multipleRulesPaged.length + 1
							}




							var data_assets = [{
									"valuation": "87.5560-112.5720",
									"businessName": "有0",
									"type": 0
								},
								{
									"valuation": "0.0000-0.0000",
									"netValue": 0.0,
									"name": "资源1_1",
									"originalValue": 22.0,
									"describe": "333",
									"type": 1,
									"seq": 1
								},
								{
									"valuation": "43.7780-56.2860",
									"netValue": 11.0,
									"name": "资源1_2",
									"originalValue": 11.0,
									"describe": "111",
									"type": 1,
									"seq": 2
								},
								{
									"valuation": "262.6680-337.7160",
									"businessName": "无0",
									"type": 0
								},
								{
									"valuation": "131.3340-168.8580",
									"netValue": 33.0,
									"name": "资源2_1",
									"originalValue": 33.0,
									"describe": "33",
									"type": 1,
									"seq": 1
								},
								{
									"valuation": "87.5560-112.5720",
									"netValue": 22.0,
									"name": "资源2_2",
									"originalValue": 22.0,
									"describe": "22",
									"type": 1,
									"seq": 2
								},
								{
									"valuation": "43.7780-56.2860",
									"netValue": 11.0,
									"name": "资源2_3",
									"originalValue": 11.0,
									"describe": "11",
									"type": 1,
									"seq": 3
								},
								{
									"valuation": "131.3340-168.8580",
									"businessName": "10年",
									"type": 0
								},
								{
									"valuation": "87.5560-112.5720",
									"netValue": 22.0,
									"name": "资源3_1",
									"originalValue": 22.0,
									"describe": "22",
									"type": 1,
									"seq": 1
								},
								{
									"valuation": "43.7780-56.2860",
									"netValue": 11.0,
									"name": "资源3_2",
									"originalValue": 11.0,
									"describe": "111",
									"type": 1,
									"seq": 2
								},
								{
									"valuation": "43.7780-56.2860",
									"netValue": 11.0,
									"name": "资源3_3",
									"originalValue": 11.0,
									"describe": "111",
									"type": 1,
									"seq": 3
								},
								{
									"valuation": "131.3340-168.8580",
									"businessName": "语文",
									"type": 0
								},
								{
									"valuation": "43.7780-56.2860",
									"netValue": 11.0,
									"name": "资源3_3",
									"originalValue": 11.0,
									"describe": "111",
									"type": 1,
									"seq": 3
								},
								{
									"valuation": "43.7780-56.2860",
									"netValue": 11.0,
									"name": "资源3_3",
									"originalValue": 11.0,
									"describe": "111",
									"type": 1,
									"seq": 3
								},
								{
									"valuation": "43.7780-56.2860",
									"netValue": 11.0,
									"name": "资源3_3",
									"originalValue": 11.0,
									"describe": "111",
									"type": 1,
									"seq": 3
								}

							]

							console.log('xxx---------', this.paginateData(data_assets))






							// 设置估值结果页特殊数字
							var minArr = this.method.switchNum(that.pageInfo.minValue, that.pageInfo.maxValue)
								.min.split('');
							var maxArr = this.method.switchNum(that.pageInfo.minValue, that.pageInfo.maxValue)
								.max.split('');
							minArr.push('12')
							var newArr = minArr.concat(maxArr);
							this.specialNumeric(newArr, "valueResultNumber")

							// 股权结构
							if (that.pageInfo.baseInfo.holderRation) {
								var holderArr = [];
								var newHolderRation = JSON.parse(that.pageInfo.baseInfo.holderRation);
								var deep = [50, 70];
								for (var i = 0; i < newHolderRation.length; i++) {
									holderArr.push({
										name: newHolderRation[i].name,
										y: Number(newHolderRation[i].value),
										sliced: false,
										selected: true
									})
								}
								that.pageInfo.baseInfo.holderRation = holderArr;
							}


							// 软性指标
							this.question = JSON.parse(that.pageInfo.question)
							this.financial = JSON.parse(that.pageInfo.financial)
							that.pageInfo.industry = JSON.parse(that.pageInfo.industry)



							// 机器学习估值法结果特殊数字(ML估值法)
							var machineLearning = this.method.technologyNum(that.pageInfo
								.relativeValuationValue).data.split('');
							if (that.pageInfo.relativeValuationValue < 0) {
								var Minus = 2;
							} else {
								var Minus = 1;
							}
							this.specialNumeric(machineLearning, "machineLearningValuationResults", Minus)

							this.boxhead[1] = this.pageInfo.baseInfo.createTime.substring(0, 4) + '年预测(万元)'
							this.income[1] = this.pageInfo.baseInfo.income
							this.netAsset[1] = this.pageInfo.baseInfo.netAsset
							this.netProfit[1] = this.pageInfo.baseInfo.netProfit

							JSON.parse(this.pageInfo.relativeValuation).baseInfo.map(el => {
								if (el.name == '息税折旧摊销前利润') {
									this.amortizeProfit[1] = el.value
								}
							})



							// 收入贡献法估值
							var revenueContributionResults = this.method.technologyNum(that.pageInfo
								.absoluteValuationValue).data.split('');
							if (that.pageInfo.absoluteValuationValue < 0) {
								var Minus = 2;
							} else {
								var Minus = 1;
							}
							this.specialNumeric(revenueContributionResults, "revenueContributionResults", Minus)


							// 资产负债表
							this.pageInfo.liaTableInfo = JSON.parse(this.pageInfo.liaTableInfo)
							var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
							var returnliabilitiesKey = Object.keys(this.pageInfo.liaTableInfo); //后台数据返回的资产负债表对象的 key


							liabilitiesKey.forEach((elem, index) => {
								returnliabilitiesKey.forEach((item, i) => {
									this.pageInfo.liaTableInfo.year.forEach((yearItem, y) => {
										if (elem == item) {
											that.liabilities[elem][y] = that.pageInfo.liaTableInfo[elem][y];
										} else if (!that.pageInfo.liaTableInfo[elem]) {
											if (this.pageInfo.liaTableInfo.year.length == 2) {
												that.liabilities[elem] = ['', '']
											} else {
												that.liabilities[elem] = ['', '', '']
											}
										}
									})
								})
							})

							// 利润表
							this.profitTableInfo = this.convertData(JSON.parse(this.pageInfo.profitTableInfo))
							var profitKey = Object.keys(this.profitTableInfo); //获取利润表对象的 key


							profitKey.forEach((elem, index) => {
								that.pageInfo.year.forEach((yearItem, y) => {
									if (elem != 'year') {
										that.profitTableInfo[elem].push('')
									}
								})
							})

							// 财务比率表
							this.financeRatio = JSON.parse(this.pageInfo.financeRatio)


							// 数据资源-------------------
							this.pageInfo.dataResource = JSON.parse(this.pageInfo.dataResource)
							this.resourceType = this.pageInfo.dataResource.isMultiple ? 1 : 0 //单一：0   多种：1
							// this.resourceType = 1
							this.dataAsset = this.pageInfo.dataResource.dataAsset




							// 把数据资源分页
							// this.newDataAsset = this.paginateData(data_assets)
							this.newDataAsset = this.paginateData(this.dataAsset)
							if (this.newDataAsset[this.newDataAsset.length - 1].length >= 2) this.lastPageEven = true
							else this.lastPageEven = false

							console.log('分页后的数据资源---', this.newDataAsset)

							this.newDataAsset.map((el, j) => {
								this.dataAssetTechnology.push({
									number: []
								})
								el.map((item, i) => {
									this.dataAssetTechnology[j].number.push({
										valuation: this.method.technologyNumInterval(item.valuation).data,
										unit: this.method.technologyNumInterval(item.valuation).unit,
										value: []
									})

									this.groupSpecialNumeric(this.method.technologyNumInterval(item.valuation).data.split(''),
										'dataAssetTechnology', j, i, 1)
								})
							})




							// 数据资源-------------------


							var dataResourceResult = []
							for (var i = 0; i < 27; i++) {
								dataResourceResult.push({
									businessName: '业务' + (i + 1),
									seq: (i + 1),
									valuation: '9893.6333-120.3856',
									dataResources: JSON.stringify([{
										netValue: i == 0 || i == 8 || i == 16 ? 0 : (10 + i),
										originalValue: 1000,
										resourceName: '资源1_1',
										value: '46.8166-60.1928'
									}, {
										netValue: '895',
										originalValue: 1000,
										resourceName: '资源1_2',
										value: '32.66-60.28'
									}])
								})
							}

							var resourceResult = this.convertData(this.pageInfo.dataResource.dataResourcesList)
							this.resourceOutcome = resourceResult.slice(1)
							// this.resourceOutcome = dataResourceResult

							this.resourceOutcome.map(item => {
								item.dataResources = JSON.parse(item.dataResources)

								const dataResourcesItem = this.convertData(item.dataResources)

								item.isExist = this.isExistence(dataResourcesItem, 'netValue')

								if (this.resourceType == 1) {
									item.dataResources.push({
										netValue: this.setNetValueTotal(dataResourcesItem, 'netValue'),
										originalValue: '',
										isExist: this.isExistence(dataResourcesItem, 'netValue'),
										resourceName: 'resourceSum',
										valuation: item.valuation
									})
								} else {
									item.dataResources.push({
										// netValue: JSON.parse(resourceResult[0].dataResources)[2].value,
										netValue: this.setNetValueTotal(dataResourcesItem, 'netValue'),
										originalValue: '',
										isExist: this.isExistence(dataResourcesItem, 'netValue'),
										resourceName: 'resourceSum',
										valuation: this.pageInfo.minValue + '-' + this.pageInfo.maxValue
									})
								}

							})


							if (this.resourceType == 1) {
								this.resourceOutcome.push({
									businessName: 'productSum',
									dataResources: [{
										netValue: JSON.parse(resourceResult[0].dataResources)[2].value,
										originalValue: '',
										resourceName: '',
										valuation: this.pageInfo.minValue + '-' + this.pageInfo.maxValue
									}]
								})
							}

							this.resourceResultFlag = this.pagedItems[this.pagedItems.length - 1].length <= 9 ? false : true

							console.log('数据资源估值结果列表------', this.resourceOutcome)
							console.log('ggggggg-------', this.pagedItems)



							// 营业收入预测--------------------  
							this.incomeForecast.year = JSON.parse(this.pageInfo.financialForecast).years

							// for (var i = 0; i < 15; i++) {
							// 	this.incomeForecast.data.push({
							// 		name: 'ww',
							// 		value: [1, 1, 1, 1, 1, 1]
							// 	})
							// }


							if (JSON.parse(this.pageInfo.financialForecast).income.incomeProduct[0].name == '合计') { //代表是单一的数据资源
								var incomeData = []
								this.incomeCharts.data.push({
									name: '',
									value: JSON.parse(JSON.parse(this.pageInfo.financialForecast).income.incomeProduct[0].value)
								})

								this.incomeCharts.data.map((el, i) => { //换算单位为万元
									el.value.map((item, j) => {
										if (item) {
											this.incomeCharts.data[i].value[j] = item
										}
									})
								})

							} else {
								var incomeData = JSON.parse(this.pageInfo.financialForecast).income.incomeProduct
								incomeData.map(el => {
									el.value = JSON.parse(el.value)
								})

								this.incomeForecast.data = this.normalizeArrayLengths(incomeData, this.incomeForecast.year.length)
								// 营业收入预测图表的数据（不含合计）
								this.incomeCharts.data = this.normalizeArrayLengths(incomeData, this.incomeForecast.year.length)
							}

							this.incomeCharts.year = JSON.parse(this.pageInfo.financialForecast).years
							this.incomeForecast.data.push({
								name: JSON.parse(this.pageInfo.financialForecast).income.incomeProduct[0].name == '合计' ?
									'营业收入' : '合计',
								value: JSON.parse(JSON.parse(this.pageInfo.financialForecast).income.productSum)
							})



							this.incomeForecast.data.map((el, i) => { //换算单位为万元
								el.value.map((item, j) => {
									if (item) {
										this.incomeForecast.data[i].value[j] = item
									}
								})
							})

							// 净利润预测
							this.netProfitForecast.year = JSON.parse(this.pageInfo.financialForecast).years


							// for (var i = 0; i < 55; i++) {
							// 	this.netProfitForecast.data.push({
							// 		name: 'ww',
							// 		value: [1, 1, 1, 1, 1, 1]
							// 	})
							// }




							if (JSON.parse(this.pageInfo.financialForecast).netProfit.netProfitProduct[0].name ==
								'合计') { //代表是单一的数据资源
								var netProfitData = []
								this.netProfitCharts.data.push({
									name: '',
									value: JSON.parse(JSON.parse(this.pageInfo.financialForecast).netProfit.netProfitProduct[0]
										.value)
								})

								this.netProfitCharts.data.map((el, i) => { //换算单位为万元
									el.value.map((item, j) => {
										if (item) {
											this.netProfitCharts.data[i].value[j] = item
										}
									})
								})
							} else {

								var netProfitData = JSON.parse(this.pageInfo.financialForecast).netProfit.netProfitProduct
								netProfitData.map(el => {
									el.value = JSON.parse(el.value)
								})
								this.netProfitForecast.data = this.normalizeArrayLengths(netProfitData, this.netProfitForecast.year
									.length)
								// 净利润预测图表的数据（不含合计）
								this.netProfitCharts.data = this.normalizeArrayLengths(netProfitData, this.netProfitForecast.year
									.length)
							}

							this.netProfitCharts.year = JSON.parse(this.pageInfo.financialForecast).years
							this.netProfitForecast.data.push({
								name: JSON.parse(this.pageInfo.financialForecast).netProfit.netProfitProduct[0].name ==
									'合计' ? '净利润' : '合计',
								value: JSON.parse(JSON.parse(this.pageInfo.financialForecast).netProfit.productSum)
							})
							this.netProfitForecast.data.map((el, i) => { //换算单位为万元
								el.value.map((item, j) => {
									if (item) {
										this.netProfitForecast.data[i].value[j] = item
									}
								})
							})


							// console.log('净利润预测----', this.netProfitForecast.data)


							// 营业收入预测表格 ,一整页可以放22个 
							this.$nextTick(() => {
								if (this.lastPageEven) {
									if (this.incomeForecast.data.length > 8) {
										this.forecastfirstPage = this.incomeForecast.data.slice(0, 8)
										this.forecastPaging = this.computationalPaging(this.incomeForecast.data.slice(8, this
											.incomeForecast.data.length), 22)
									} else {
										this.forecastfirstPage = this.incomeForecast.data
									}

								} else {
									this.forecastfirstPage = this.incomeForecast.data.slice(0, 2)
									this.forecastPaging = this.computationalPaging(this.incomeForecast.data.slice(2, this
										.incomeForecast.data.length), 22)
								}


								if (this.forecastPaging.length > 0) { //营业收入预测有分页
									let incomHeight = this.forecastPaging[this.forecastPaging.length - 1].data.length * 61 + 61

									if (1754 - 400 - incomHeight > 732) {
										// console.log('oo----------', (1754 -561- 662 - incomHeight))
										this.isNextPageNetProfit = false
										if (1754 - 400 - incomHeight > 100) {
											let num_ = parseInt((1754 - 561 - 662 - incomHeight) / 61)
											this.netProfitNum = num_

											this.netProfitfirstPage = this.netProfitForecast.data.slice(0, num_)
											this.netProfitPaging = this.computationalPaging(this.netProfitForecast.data.slice(num_,
												this.netProfitForecast.data.length), 22)
											console.log('净利润的个数---', num_)

										}
									} else {
										this.isNextPageNetProfit = true
										this.netProfitfirstPage = this.netProfitForecast.data.slice(0, 8)
										this.netProfitPaging = this.computationalPaging(this.netProfitForecast.data.slice(8, this
											.netProfitForecast.data.length), 22)
										console.log('完整的一页净利润---')

									}

								} else {
									console.log('mmmmmmmmmmmm')
									this.isNextPageNetProfit = true
									this.netProfitfirstPage = this.netProfitForecast.data.slice(0, 8)
									this.netProfitPaging = this.computationalPaging(this.netProfitForecast.data.slice(8, this
										.netProfitForecast.data.length), 22)
								}




								this.addPageWatermark(this.pageInfo.isTestAccount)


							})

							// 营业收入预测--------------------



						}

					})
			},
			computationalPaging(allData, pageSize, excess) { //pageSize：每页最多几个元素 
				let pagination = []
				let pageCount = Math.ceil(allData.length / pageSize);

				// 遍历所有页  
				for (let i = 0; i < pageCount; i++) {
					// 计算当前页的起始索引  
					let startIndex = i * pageSize;
					// 计算当前页的结束索引（注意不要超出数据长度）  
					let endIndex = Math.min(startIndex + pageSize, allData.length);

					// 截取当前页的数据  
					let currentPageData = allData.slice(startIndex, endIndex);

					// 将当前页的数据添加到分页数组中  
					pagination.push({
						data: currentPageData
					});
				}

				return pagination

			},
			grouping(arr) {
				const groupedByName = arr.reduce((accumulator, currentValue) => {
					// 如果 accumulator 中还没有当前 name 的键，则添加它并初始化为一个空数组
					if (!accumulator[currentValue.businessName]) {
						accumulator[currentValue.businessName] = [];
					}
					// 将当前项添加到对应 name 的数组中
					accumulator[currentValue.businessName].push(currentValue);
					// 返回 accumulator，以便在下一次迭代中使用
					return accumulator;
				}, {}); // 初始 accumulator 是一个空对象

				// 将分组后的对象转换为数组
				const groupedArray = Object.entries(groupedByName).map(([name, items]) => ({
					name,
					items
				}));

				return groupedArray
			},
			// 特殊数字的转换
			specialNumeric: function(data, key, minus) { //minus==1 代表正数   minus==2代表负数
				if (minus == 2) {
					this[key].push({
						val: '12',
						top: "0px"
					})
				}
				data.forEach((val, i) => {
					if (val == ".") {
						this[key].push({
							val: "11",
							top: "62px",
						})
					} else {
						this[key].push({
							val: val,
							top: "0px"
						})
					}
				})
			},
			groupSpecialNumeric: function(data, key, j, i, minus) { //minus==1 代表正数   minus==2代表负数   interval:是否是区间值
				if (minus == 2) {
					this[key][j].number[i].value.push({
						val: '12',
						top: "0px"
					})
				}
				data.forEach((val, k) => {
					if (val == ".") {
						this[key][j].number[i].value.push({
							val: "11",
							top: "62px",
						})
					} else if (val == '-') {
						this[key][j].number[i].value.push({
							val: '12',
							top: "0px"
						})
					} else {
						// console.log('kk---', this[key][j].number[i].value)

						this[key][j].number[i].value.push({
							val: val,
							top: "0px"
						})
					}
				})
			},
			// 日期格式化
			dateFormate: function(date) {
				if (date) {
					var dataStr = date.split("-");
					var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
					return newDataStr;
				}
			},
			// 得分校对
			scoreFormate: function(score) {
				if (this.pageInfo.baseInfo.companyName) {
					for (var i = 0; i < this.pageInfo.question.length; i++) {
						if (this.pageInfo.question[i].name == score) {
							return this.pageInfo.question[i].value + '分';
						}
					};
				}
			},
			// 估值结果格式化
			resultFormate: function(min, max) {
				var val = '';
				if (min) {
					return this.method.valuationResultConversion(min, max)
				} else {
					return '无数据'
				}
			},
			valueFormate: function(val) {
				if (val) {
					return this.method.resultConversion(val)
				} else {
					return '无数据'
				}
			},
			/*页脚日期格式转换*/
			replaceAll: function(val) {
				if (val) {
					return val.replace(new RegExp("-", "gm"), ".");
				}

			},
			pdf: function() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + '数据估值报告.pdf'
				var pageArr = document.getElementsByClassName("content");
				this.pdfArr = [];
				// this.pdfArr.push('homePage','page-one')
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				console.log(this.pdfArr)
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);
			}
		}


	})
</script>

<style scoped lang="less">
	.tools {
		width: 1160px;
		height: 60px;
		margin: 0 auto;
		padding-top: 10px;
		box-sizing: border-box;
	}

	.tools button {
		float: right;
		width: 120px;
		padding: 8px;
		border: 1px #aaa solid;
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		transition: background-color 0.3s;
		background: #B1781E;
		color: #fff;
		margin-right: 20px;
		cursor: pointer;
	}

	.page {
		width: 100%;
		height: 1754px;
		position: relative;
		line-height: normal;
		vertical-align: middle;
	}

	.content {
		width: 1240px;
		height: 100%;
		background: #fff;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		border-top: 1px solid #ccc;
	}

	.page-content {
		padding-top: 114px;
		padding-left: 110px;
		padding-right: 110px;
	}

	.pagination {
		width: 52px;
		height: 54px;
		line-height: 54px;
		font-size: 20px;
		color: #fff;
		text-align: center;
		position: absolute;
		bottom: 37px;
		left: 50%;
		margin-left: -26px;
		background: url(../../../assets/img/report/pageNumber.png) bottom center no-repeat;
		background-size: 100% 100%;
	}

	/* 封面 */

	#homePage {
		overflow: hidden;
		background: url(../../../assets/img/report/pageIndex_bg.png)no-repeat center center;
	}

	.logoBox {
		overflow: hidden;
	}

	#logo {
		width: 293px;
		margin: 81px 0 0 75px;
	}

	.stock-logo {
		margin: 81px 0 0 75px;
		display: flex;
		align-items: center;
	}

	.stock-logo .logoXian {
		width: 2px;
		height: 99px;
		background: #7F7F7F;
		margin: 0 20px;
	}

	.stock-logo img {
		height: 80px;
	}

	#page-stock {
		padding: 0;
	}

	#page-stock>.introduce-img {
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
	}

	.homePage h4 {
		font-size: 24px;
		font-weight: 400;
		line-height: 100%;
		color: #000;
		text-align: center;
		margin: 42px auto 0;
		text-align: center;
	}

	.homePage h6 {
		font-size: 38px;
		font-weight: 400;
		color: #1416F4;
		line-height: 100%;
		margin-top: 20px;
		text-align: center;
	}

	.homePage .content {
		border: none;
	}

	.companyName {
		font-size: 50px;
		font-weight: bold;
		color: #000;
		text-align: center;
		margin: 118px 0 40px 0;
	}

	.homePage>div>div.homePageLine {
		width: 796px;
		height: 6px;
		background: #1416F4;
		margin: 0 auto;
	}

	.homePage .reportType {
		font-size: 130px;
		font-weight: bold;
		color: #1416F4;
		margin: 60px 0 10px 0;
		text-align: center;
	}

	.homePage h3 {
		font-size: 34px;
		font-weight: bold;
		color: #388BFF;
		text-align: center;
		margin-bottom: 22px;
	}

	.page-one-footer {
		position: absolute;
		bottom: 50px;
		height: 21px;
		width: 1100px;
		margin: 0 34px;
	}

	.page-one-footer>p {
		float: right;
		margin-right: 193px;
		font-size: 18px;
		color: #282828;
		line-height: 1;
	}

	.page-one-footer>p>span:nth-child(2) {
		margin: 0 5px;
	}

	.page-one-footer>p>span:last-of-type {
		color: #0D0D0D;
	}

	.page-one-footer>div.date {
		float: right;
		font-size: 18px;
		line-height: 1;
		color: #0D0D0D;
	}

	/* 新目录 */

	.page-catalogue h2 {
		font-size: 48px;
		font-weight: bold;
		color: #0F060B;
		margin: 36px auto 69px;
		text-align: center;
		line-height: 1;
	}

	.page-catalogue .generalize {
		position: relative;
	}

	.page-catalogue .generalize>div {
		padding-bottom: 70px;
		display: flex;
		align-items: center;
		position: relative;

		.serialNumber {
			width: 43px;
			font-size: 34px;
			color: #282828;
			font-weight: bold;
			position: relative;
			text-align: right;
		}

		.serialNumber::after {
			display: block;
			content: '';
			width: 6px;
			height: 30px;
			background: #1416f4;
			position: absolute;
			top: 50%;
			right: -18px;
			transform: translateY(-50%);
			z-index: 3;
		}

		.catalogue-category {
			width: 100%;
			height: 2px;
			background: url(../../../assets/img/report/catalogue_.png) left center repeat;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.spreadhead {
			font-size: 28px;
			color: #282828;
			font-weight: bold;
			padding-left: 50px;
			padding-right: 20px;
			background: #fff;
			position: relative;
		}

		.spreadhead::before {
			display: block;
			content: '';
			width: 6px;
			height: 30px;
			background: #1416f4;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			z-index: 3;
		}

		.catalogue-pagination {
			font-size: 20px;
			color: #282828;
			background: #fff;
			padding-left: 20px;
		}

		.dottedLine {
			height: 2px;
			width: 748px;
			margin: 0 20px 0;
			background: url(../../../assets/img/report/catalogue_.png) left center repeat;
		}

	}

	.page-catalogue .generalize .haveTwoLevels {
		padding-bottom: 48px;
	}

	.page-catalogue .generalize>ul {
		padding-left: 104px;

		li:last-child {
			margin-bottom: 20px;
		}

		li {
			height: 48px;
			position: relative;

			>div {
				width: 100%;
				height: 2px;
				background: url(../../../assets/img/report/catalogue_.png) left center repeat;
				display: flex;
				align-items: center;
				justify-content: space-between;

				span:first-child {
					font-size: 22px;
					color: #282828;
					padding-right: 20px;
					background: #fff;
				}

				span:last-child {
					font-size: 20px;
					color: #282828;
					background: #fff;
					padding-left: 20px;
				}
			}

		}
	}

	// 基本信息
	.valueResultTitle {
		display: flex;
		// align-items: center;
	}

	.icon_ {
		height: 52px;
		margin-right: 25px;
		vertical-align: middle;
	}

	.icon_+span {
		font-size: 30px;
		font-weight: bold;
		color: #282828;
		line-height: 52px;
	}

	.broadCategories {
		font-size: 32px;
		font-weight: 600;
		color: #282828;
		margin-bottom: 30px;
	}

	.broadHeading {
		font-size: 30px;
		color: #282828;
		font-weight: bold;
		margin-bottom: 14px;
		text-indent: 2em;
		position: relative;
		left: -28px;
	}

	.secondaryHeading,
	.secondaryHeadingBrackets {
		font-size: 26px;
		color: #282828;
		font-weight: 600;
		margin-bottom: 14px;
		text-indent: 2em;
	}

	.secondaryHeadingBrackets {
		position: relative;
		left: -17px;
	}


	.text {
		font-size: 20px;
		color: #282828;
		line-height: 34px;
		text-indent: 2em;
		margin-left: 13px;
	}

	.text_ {
		font-size: 0px;
		color: #282828;
		text-indent: 2em;
		text-align: justify;
		line-height: 34px;
		margin-left: 13px;

		i:first-child {
			margin-left: 2em;
		}

		i {
			font-size: 20px;
			display: inline;
		}
	}

	.secondLevelText {
		margin-bottom: 24px;
	}

	.threeLevelText {
		margin-bottom: 14px;
	}

	.stairText {
		margin-bottom: 42px;
	}

	#companyName {
		font-size: 24px;
		font-weight: bold;
		color: #000000;
		margin-bottom: 20px;
	}

	.baseInfoCont,
	.productCont,
	.groupCont,
	.mainBusinessCont,
	.situationCont {
		width: 100%;
		overflow: hidden;
		margin: 20px 0 40px 50px;
	}

	.situationCont ul li span,
	.baseInfoCont ul li span,
	.productCont ul li span,
	.groupCont ul li span {
		font-size: 20px;
		font-weight: 400;
		color: #010000;
		line-height: 38px;
	}

	.mainBusinessCont>p {
		font-size: 20px;
		color: #282828;
		line-height: 40px;
		text-align: justify;
	}

	.equityStructure {
		position: relative;
	}

	.piePedestal {
		width: 600px;
		position: absolute;
		top: 352px;
		left: 50%;
		margin-left: -289px;
	}

	// 盖章页
	.stamp {
		width: 68%;
		line-height: 41px;
		margin: 10% auto;
		font-size: 20px;
		font-weight: 400;
		text-indent: 2em;
		margin-top: 31%;
		text-align: justify;
		color: #282828;
	}

	.detail_result {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		margin-top: 54px;
		/* overflow: hidden; */
	}

	.detail_result h4 {
		float: left;
		height: 80px;
		margin-top: 6px;
		border-top: 3px solid #1416F4;
		border-bottom: 3px solid #1416F4;
		font-size: 38px;
		color: #1416F4;
		line-height: 80px;
		padding: 0 34px;
		font-weight: bold;
	}

	.stamptime {
		width: 68%;
		margin: 10% auto;
		font-size: 20px;
		text-align: right;
		line-height: 39px;
		margin-top: 29%;
	}

	.stamptime>p {
		font-size: 22px;
		margin-top: 29px;
		line-height: normal;
		color: #282828;
	}

	.detail_result>div {
		position: relative;
	}

	.locationUnit {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.numberBox img {
		margin-right: 4px;
	}

	.numberBox img:last-child {
		// margin-right: 0;
	}

	.numberBox p.unit {
		width: 56px;
		font-size: 28px;
		font-weight: bold;
		color: #1416F4;
		text-align: center;
		position: relative;
		top: 20px;
	}

	.numberBox p.unit+img {
		margin-right: 17px !important;
	}

	// 软性指标
	.softIndexScore {
		width: 1023px;
		height: 816px;
		background-size: contain;
		margin: 0 auto 30px;
		position: relative;

		>img {
			position: absolute;
			left: 0;
			top: 23px;
		}

		div {
			width: 378px;
			height: 378px;
			margin: 0 auto;
			transform: translateY(44%);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			span {
				width: 100%;
				color: #1416F4;
				text-align: center;
				font-weight: bold;
			}

			span:first-child {
				font-size: 53px;
				margin: 18px 0 10px;
			}

			span:last-child {
				font-size: 28px;
			}
		}
	}

	.indexScore {
		display: flex;
		justify-content: space-between;

		li:last-child {
			width: 45%;
		}

		li {
			width: 48%;

			.indicators {
				position: relative;
				margin-top: 50px;
				text-align: center;

				img {
					height: 290px;
				}

				p {
					position: absolute;
					text-align: center;

					span {
						font-size: 20px;
						color: #282828;
						display: block;
					}

					span:last-child {
						font-weight: bold;
					}
				}

				p:nth-of-type(1) {
					top: -41px;
					left: 52%;
				}

				p:nth-of-type(2) {
					top: 94px;
					right: 0;
				}

				p:nth-of-type(3) {
					bottom: -25px;
					right: 11%;
				}

				p:nth-of-type(4) {
					bottom: -25px;
					left: 11%;
				}

				p:nth-of-type(5) {
					top: 94px;
					left: 0;
				}

			}

			.financeIndicators {
				position: relative;
				margin-top: 50px;
				text-align: center;

				img {
					height: 290px;
				}

				p {
					position: absolute;
					text-align: center;

					span {
						font-size: 20px;
						color: #282828;
						display: block;
					}

					span:last-child {
						font-weight: bold;
					}
				}

				p:nth-of-type(1) {
					top: -23px;
					left: 58%;
				}

				p:nth-of-type(2) {
					top: 125px;
					right: 14px;
				}

				p:nth-of-type(3) {
					bottom: -29px;
					right: 26%;
				}

				p:nth-of-type(4) {
					top: 125px;
					left: 14px;
				}

			}

		}
	}

	.scoreDescription {
		font-size: 20px;
		color: #282828;
		line-height: 35px;
		text-align: justify;
		margin: 0 auto;
		text-indent: 2em;
	}

	.guidelines {
		p {
			font-size: 20px;
			color: #282828;
			line-height: 42px;
			margin: 0 auto;
			text-indent: 2em;
			text-align: justify;
		}
	}

	.indicator {
		display: inline-block;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		margin-top: 76px;
		margin-bottom: 70px;

		>ul {
			width: 100%;
			position: absolute;
		}

		>div {
			position: absolute;
		}
	}

	.basicIndexScore+div {
		width: 100%;
		top: 60px;
		left: 0;
		text-align: center;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
			margin-top: 11px;
		}
	}

	.basicIndexScore {
		top: 0;

		li {
			width: 221px;
			height: 256px;
			background: url(../../../assets/img/report/basicIndexScore.png) center center no-repeat;
			position: absolute;
			top: 115px;

			p {
				width: 100%;
				text-align: center;
			}

			p:first-child {
				font-size: 24px;
				font-weight: bold;
				color: #1416F4;
				margin-top: 59px;
			}

			p:last-child {
				font-size: 20px;
				color: #fff;
				position: absolute;
				bottom: 26.5%;
			}
		}
	}

	.riskIndicatorScore+div {
		top: -38px;
	}

	.riskIndicatorScore {
		li {
			width: 262px;
			height: 155px;
			background: url(../../../assets/img/report/riskIndicatorScore.png) center center no-repeat;
			top: 30px;

			p {
				color: #000 !important;
			}

			p:first-child {
				margin-top: -27px;
			}

			p:last-child {
				bottom: 77%;
			}
		}
	}

	.qualityIndexScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 49px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.qualityIndexScore {
		top: -103px;
		left: -138px;

		li {
			position: absolute;

			>div {
				width: 158px;
				height: 86px;
				background: url(../../../assets/img/report/riskIndicatorGrade.png) center center no-repeat;
				margin: 0 auto 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				p {
					text-align: center;
					font-size: 18px;
					margin-top: 5px;
					color: #000;
				}

				p:last-child {
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
	}

	.economicScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: -45px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.economicScore {
		top: -22px;

		li {
			width: 250px;
			height: 70px;
			background: url(../../../assets/img/report/economicIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			span {
				line-height: 70px;
				color: #1416F4;
			}

			span:first-child {
				font-size: 20px;
			}

			span:last-child {
				font-size: 24px;
				font-weight: bold;
				margin-left: 10px;
			}
		}
	}

	.circulationScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 7px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.circulationScore {
		top: 28px;

		li {
			width: 228px;
			height: 254px;
			background: url(../../../assets/img/report/circulationIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			p:first-child {
				font-size: 24px;
				color: #1416F4;
				margin-top: 71px;
				font-weight: bold;
			}

			p:last-child {
				width: 100%;
				font-size: 20px;
				color: #fff;
				position: absolute;
				bottom: 19%;
			}
		}
	}

	.costIndexScore+div {
		width: 288px;
		height: 288px;
		text-align: center;
		position: absolute;
		top: 18px;
		left: 296px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		p {
			width: 100%;
			font-size: 30px;
			font-weight: bold;
			color: #fff;
			margin-top: 27px;
		}
	}

	.costIndexScore {
		top: 189px;

		li {
			width: 160px;
			height: 160px;
			background: url(../../../assets/img/report/costIndexIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			p:first-child {
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				margin-top: 56px;
			}

			p:last-child {
				font-size: 20px;
				color: #fff;
				margin-top: 8px;
			}
		}
	}

	.interpretation {
		margin: -15px 0 0 24px;

		li {
			font-size: 16px;
			color: #282828;
			line-height: 34px;
			text-indent: -1.6em;
			text-align: justify;
		}
	}

	.profitData {
		width: 511px;
		height: 402px;
		background: url(../../../assets/img/report/forecast.png)center center repeat;
		background-size: 100% 100%;
		margin: 0 auto;
		position: relative;
		top: -26px;
	}

	.profitData svg:nth-of-type(1) {
		position: relative;
		// top: -14px;
	}

	.arrows {
		width: 60px;
		height: 75px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: -17px;
	}

	.arrows+img {
		height: 388px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: -40px;
	}

	.rotateYear {
		font-size: 18px;
		fill: #fff;
		font-weight: bold;
	}

	.rotateData {
		width: 50px;
		font-size: 14px;
		fill: #fff;
		text-anchor: middle;
	}

	.rotateDataBlue {
		width: 50px;
		font-size: 14px;
		fill: #1416F4;
		text-anchor: middle;
	}

	.rotateData:nth-of-type(3),
	.rotateDataBlue:nth-of-type(5),
	.rotateData:nth-of-type(7) {
		font-weight: bold;
	}

	.specialNumeric>img {
		display: block;
		margin: 0 auto;
	}

	.machineLearningValuationResults {
		width: 100%;
		height: 434px;
		padding-top: 30px;
		position: relative;
		top: -26px;
		background: url(../../../assets/img/report/machineLearningValuationResults.jpg) center center repeat;
		background-size: 100% 100%;
		margin: 37px auto 0;
	}

	.machineLearningValuationResults .numberBox {
		width: 636px;
		height: 135px;
		position: absolute;
		top: 30px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.valuationMethod {
		font-size: 22px;
		font-weight: bold;
		color: #000;
	}

	.revenueContributionInterpretation {


		li {
			font-size: 20px;
			color: #282828;
			line-height: 42px;
			text-align: justify;
		}
	}

	.revenueContributionInterpretation+.numberBox {
		width: 520px;
		height: 119px;
		background: url(../../../assets/img/report/absoluteValuationResult.png) center center no-repeat;
		margin: 48px auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.contribution1 {
		width: 242px;
		height: 196px;
		margin: 45px auto;
		position: relative;
	}

	.contribution1::before {
		content: '';
		display: block;
		width: 3px;
		height: 47px;
		background: #1416F4;
		position: absolute;
		left: 50%;
		top: -46px;
		transform: translateX(-50%);
	}

	.contribution1::after {
		content: '';
		display: block;
		width: 3px;
		height: 47px;
		background: #1416F4;
		position: absolute;
		left: 50%;
		bottom: -45px;
		transform: translateX(-50%);
	}

	.contribution2 {
		width: 825px;
		height: 82px;
		border-top: 3px solid #1416F4;
		margin: 0 auto;
		position: relative;

		ul {
			display: flex;
			position: relative;

			li {
				position: relative;

				>div {
					width: 195px;
					height: 200px;
					background: url(../../../assets/img/report/contributionIcon.png) center center no-repeat;
					margin-top: 82px;
					margin-bottom: 45px;
					position: relative;
				}

				p {
					width: 170px;
					height: 66px;
					line-height: 66px;
					background: #EEEEFF;
					border: 2px solid #1416F4;
					font-size: 20px;
					color: #282828;
					text-align: center;
					margin-left: 16px;
				}

				>img {
					width: 21px;
					transform: rotate(-90deg);
					position: absolute;
					right: -18px;
					bottom: 13px;
				}

				>div::before {
					content: '';
					display: block;
					width: 47px;
					height: 85px;
					background: url(../../../assets/img/report/contribution2.png) center center no-repeat;
					background-size: 100% 100%;
					position: absolute;
					left: 50%;
					top: -85px;
					transform: translateX(-50%);
				}

				>div::after {
					content: '';
					display: block;
					width: 3px;
					height: 47px;
					background: #1416F4;
					position: absolute;
					left: 50%;
					bottom: -45px;
					transform: translateX(-50%);
				}

			}

			>li:first-child {
				left: -96px;
			}

			>li:last-child {
				right: 52px;
			}
		}
	}

	#incomeStatement,
	#netProfitChart {
		width: 100%;
		height: 662px;
		margin: 0 0 50px 11px;
	}

	.revenueForecastingBase {
		width: 100%;
		position: absolute;
		bottom: 21px;
	}

	.valuationResultDV {
		width: 890px;
		height: 294px;
		background: url(../../../assets/img/report/valuationResultDVBox.png) center center no-repeat;
		margin: 111px auto 0;

		.numberBox {
			width: 817px;
			height: 151px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			position: relative;
			top: 106px;

			>div {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					margin-right: 5px;
				}

				span {
					font-size: 28px;
					font-weight: bold;
					color: #1416F4;
					margin-top: 40px;
				}
			}

			>p {
				width: 100%;
				font-size: 32px;
				font-weight: bold;
				color: #000000;
				text-align: center;
			}
		}
	}

	.valuationResultDV+img {
		margin-top: -20px;
	}


	.netBookValue {
		width: 950px;
		height: 608px;
		margin: 0 auto;
		position: relative;

		thead {
			th {
				width: 474px;
				height: 71px;
				line-height: 71px;
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
			}

			>th:first-child {
				background: url(../../../assets/img/report/dataAssetName.png) center center no-repeat;
				margin-right: 3px;
			}

			>th:last-child {
				background: url(../../../assets/img/report/bookValue.png) center center no-repeat;
			}

		}

		tbody {
			border: 2px solid #2426F4;
			position: absolute;
			top: 79px;

			tr {
				td {
					width: 474px;
					height: 54px;
					line-height: 54px;
					text-align: center;
					font-size: 20px;
					color: #000000;
					border-bottom: 2px solid #2426F4;
				}

				>td:first-child {
					padding-right: 3px;
				}
			}

			>tr:last-child {
				td {
					border: none;
				}
			}

			tr.summation {
				td {
					font-weight: bold;
				}
			}

		}
	}


	// 免责声明
	/* 新版封底 */
	#page-statement {
		background: url(../../../assets/img/report/versoStatement.jpg) center center no-repeat;
	}

	#page-statement ul {
		margin-top: 140px;
	}

	#page-statement ul li:not(:last-child) {
		margin-bottom: 120px;
	}

	#page-statement ul li>div {
		width: 254px;
		padding: 30px 30px 55px;
		box-sizing: border-box;
		border: 8px solid #1416F4;
		border-right: none;
		position: relative;
	}

	#page-statement ul li>div i:nth-of-type(1) {
		display: block;
		width: 8px;
		height: 40px;
		background: #1416F4;
		position: absolute;
		top: 0;
		right: 0;
	}

	#page-statement ul li>div i:nth-of-type(2) {
		display: block;
		width: 8px;
		height: 40px;
		background: #1416F4;
		position: absolute;
		bottom: 0;
		right: 0;
	}

	#page-statement ul li>div p:nth-of-type(1) {
		width: 130px;
		font-size: 30px;
		font-weight: bold;
		color: #1416F4;
		border-bottom: 2px #1416F4 solid;
	}

	#page-statement ul li>div p:nth-of-type(2) {
		width: 986px;
		font-size: 20px;
		color: #282828;
		text-indent: 2em;
		text-align: justify;
		line-height: 34px;
		margin-top: 20px;
	}

	#page-flow {
		background: url(../../../assets/img/report/relationFlow.png) center center no-repeat;
	}

	#page-flow .ourCompany {
		font-size: 36px;
		font-weight: bold;
		color: #282828;
		margin: 26px 0 80px;
	}

	#page-flow .ourCompany+p {
		font-size: 30px;
		font-weight: bold;
		color: #282828;
		margin-bottom: 35px;
	}

	#page-flow .contact li {
		font-size: 0;
		display: flex;
	}

	#page-flow .contact li span {
		font-size: 20px;
		color: #282828;
		display: inline-block;
		margin-bottom: 16px;
	}

	#page-flow .contact li span:first-child {
		width: 100px;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
	}


	#page-flow .contact li span:first-child i {
		font-weight: bold;
	}

	#page-flow .flowQRCode {
		display: flex;
		margin-top: 81px;
	}

	#page-flow .flowQRCode li {
		margin-right: 34px;
	}

	#page-flow .flowQRCode div {
		width: 200px;
		height: 200px;
		border-radius: 6px;
		border: 2px solid #1416F4;
		padding: 12px;
		box-sizing: border-box;
		margin-bottom: 20px;
	}

	#page-flow .flowQRCode div img {
		width: 100%;
	}

	#page-flow .flowQRCode p {
		font-size: 20px;
		color: #282828;
		text-align: center;
	}


	// 免责声明


	.accessoryTitle {
		display: flex;
		justify-content: space-between;
	}

	.accessoryTitle i:nth-child(1) {
		font-size: 24px;
		color: #282828;
	}

	.accessoryTitle i:nth-child(2) {
		font-size: 24px;
		color: #282828;
		font-weight: bold;
	}

	.accessoryTitle i:nth-child(3) {
		font-size: 20px;
		color: #282828;
	}

	.analysis {
		width: 880px;
		height: 466px;
		margin: 30px auto;
		background-size: contain !important;
		display: flex;
		align-items: center;

		>p {
			width: 326px;
			height: 323px;
			line-height: 323px;
			font-size: 30px;
			font-weight: bold;
			text-align: center;
			color: #282828;
			margin-top: 37px;
			margin-left: 64px;
		}

		>div {


			>p {
				height: 100px;
				line-height: 100px;
				font-size: 20px;
				color: #282828;
				text-align: center;
				margin-top: 10px;
			}
		}
	}

	.repaymentAnalysis {
		background: url(../../../assets/img/report/analysisThree.png) center center no-repeat;

		>div {
			width: 400px;
			height: 354px;
			padding: 26px 0 0;
		}
	}


	.payoffProfile {
		background: url(../../../assets/img/report/analysisTwo.png) center center no-repeat;

		>div {
			width: 400px;
			height: 298px;
			padding: 18px 0 0;

			>p {
				height: 118px;
				line-height: 118px;
				margin-top: 20px;
			}
		}
	}

	.growthAnalysis {
		width: 880px;
		height: 503px;
		background: url(../../../assets/img/report/analysisGrowth.png) center center no-repeat;
		background-size: contain;
		margin: 30px auto;

		ul {
			width: 84%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 auto;

			li:nth-child(1),
			li:nth-child(3) {
				width: 228px;
				height: 269px;
				background: url(../../../assets/img/report/growthScore.png) center center no-repeat;
				background-size: contain;
				text-align: center;

				>p:nth-child(1) {
					height: 180px;
					line-height: 180px;
					font-size: 24px;
					color: #1416F4;
					font-weight: bold;
				}

				>p:nth-child(2) {
					width: 62%;
					height: 41px;
					margin: 7px auto 0;
					font-size: 18px;
					color: #fff;
					line-height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

			}

			li:nth-child(2) {
				width: 228px;
				height: 228px;
				line-height: 228px;

				>p {
					font-size: 30px;
					font-weight: bold;
					color: #282828;
					text-align: center;
				}
			}
		}
	}

	.MLMIntroduction {
		margin-top: 38px;

		p {
			font-size: 18px;
			color: #282828;
			line-height: 36px;
			text-indent: 2em;
		}

		.secondIndent {
			margin-left: 54px;
		}

		img {
			width: 233px;
			margin: 6px 0 6px 38px;
		}
	}

	.formula-container {
		margin-top: 38px;

		h3 {
			font-size: 20px;
			color: #282828;
			font-weight: bold;
		}

		p {
			margin: 16px 0;

			span {
				text-align: justify;
			}

			span:first-child {
				font-size: 14px;
				color: #1416F4;
				margin-right: 5px;
				vertical-align: top;
			}

			span:last-child {
				width: 98%;
				font-size: 18px;
				color: #282828;
			}
		}

		.thereinto {
			font-size: 18px;
			color: #282828;
			margin-left: 59px;
		}
	}

	.references p {
		font-size: 18px;
		color: #282828;
		line-height: 38px;
	}

	.units {
		font-size: 20px;
		color: #282828;
		text-align: right;
		margin: 10px 0 20px;
	}

	// 预测表
	.forecastedTable {
		position: relative;
		border: 1px solid #1416F4;
		// margin-top: 50px;

		>i {
			width: 100%;
			position: absolute;
			display: block;
		}

		>i:nth-of-type(1)::before {
			content: '';
			width: 30px;
			height: 30px;
			background: url(../../../../src/assets/img/report/forecastLeftTop.png) center center no-repeat;
			display: block;
			position: absolute;
			top: -1px;
			left: 0;
		}

		>i:nth-of-type(1)::after {
			content: '';
			width: 30px;
			height: 30px;
			display: block;
			position: absolute;
			background: url(../../../../src/assets/img/report/forecastRightTop.png) center center no-repeat;
			top: -1px;
			right: 0;
		}

		>i:nth-of-type(2) {
			bottom: 0;
		}

		>i:nth-of-type(2)::before {
			content: '';
			width: 30px;
			height: 30px;
			background: url(../../../../src/assets/img/report/forecastLeftBottom.png) center center no-repeat;
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		>i:nth-of-type(2)::after {
			content: '';
			width: 30px;
			height: 30px;
			display: block;
			position: absolute;
			background: url(../../../../src/assets/img/report/forecastRightBottom.png) center center no-repeat;
			bottom: 0;
			right: 0;
		}

		li:nth-of-type(1) {
			border-top: none;
			background: rgba(20, 22, 244, 0.07);

			span {
				color: #1416F4;
				font-weight: bold;
			}
		}

		li:not(:nth-of-type(1)) {
			span:not(:first-child) {
				text-align: right;
			}
		}

		li {
			height: 61px;
			line-height: 61px;
			border-top: 1px solid #1416F4;
			display: flex;

			span:first-child {
				color: #1416F4;
			}

			span.total,
			span.total~span {
				font-weight: bold;
			}

			span {
				font-size: 16px;
				color: #282828;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				position: relative;
				padding: 0 10px;
			}

			span:not(:last-child)::after {
				content: '';
				width: 1px;
				height: 36px;
				background: #1416F4;
				display: block;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}

		}

	}

	.dataAsset {
		margin-bottom: 60px;
		margin-left: 13px;


		.businessName {
			width: 950px;
			height: 68px;
			line-height: 80px;
			background: url(../../../../src/assets/img/report/businessName.png) center center no-repeat;
			background-size: 100% 100%;
			padding-left: 57px;

			span {
				width: 47%;
				font-weight: bold;
				font-size: 22px;
				color: #fff;
			}
		}

		.dataAssetNameSpecialty {
			width: 100%;
			height: 68px;
			background: url(../../../../src/assets/img/report/dataAssetNameSpecialty.png) center center no-repeat;
			background-size: 100% 100%;
			display: flex;
			align-items: center;

			span:not(:last-child) {
				border-right: 1px solid #1416F4;
			}

			span:first-child {
				width: 46%;
			}

			span {
				width: 27%;
				height: 44px;
				line-height: 44px;
				text-align: center;
				font-weight: bold;
				font-size: 20px;
				color: #fff;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		>ul {
			border: 1px solid #1416F4;
			border-top: none;
			margin-top: 10px;



			li {
				border-top: 1px solid #1416F4;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				>span {
					width: 27%;
					height: 58px;
					line-height: 58px;
					font-size: 18px;
					color: #282828;
					padding: 0 20px;
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}

				span:first-child {
					width: 46%;
					justify-content: flex-start;
				}

				>span:not(:first-child) {
					text-align: right;
					border-left: 1px solid #1416F4;
				}

				.yearLimit {
					width: 100%;
					height: 58px;
					line-height: 58px;
					align-items: center;
					padding-left: 20px;
					display: flex;
					border-top: 1px solid #1416F4;

					>span:first-child {
						font-size: 18px;
						color: #282828;
						font-weight: bold;
					}

					>span:last-child {
						width: 228px;
						height: 38px;
						border: 1px solid #1416F4;
						font-size: 18px;
						color: #282828;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 0 10px;

						i:first-child {
							font-weight: bold;
						}

					}
				}

			}

		}

	}

	.dataAsset+.dataAssetTechnology {
		margin-bottom: 81px;
	}

	.businessValuationResults {
		margin: 20px auto 64px;
		position: relative;

		>p {
			width: 100%;
			font-size: 30px;
			font-weight: bold;
			color: #282828;
			text-align: center;
			position: absolute;
			top: 167px;
		}

		.dataAssetTechnology {
			top: 23px;
		}
	}


	.dataAssetTechnology {
		width: 880px;
		height: 140px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		background: url(../../../assets/img/dataResource/valuationResult.png) center center no-repeat;
		background-size: 100% 100%;
		position: relative;
	}

	.resourceName {
		font-size: 30px;
		font-weight: bold;
		color: #282828;
	}

	.resDescribe {
		line-height: 42px;
		font-size: 20px;
		color: #282828;
		text-indent: 2em;
		text-align: justify;
		margin: 28px auto 0;
	}

	.dataAssetTotal {
		width: 950px;
		height: 58px;
		line-height: 58px;
		margin: 20px auto 0;
		border: 1px solid #1416F4;
		display: flex;
		background: rgba(20, 22, 244, 0.1);

		>span:first-child i {
			font-size: 18px;
			color: #282828;
			font-weight: bold;
		}

		>span {
			width: 27%;
			height: 58px;
			font-size: 18px;
			color: #282828;
			padding: 0 20px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		span:first-child {
			width: 46%;
			justify-content: flex-start;
		}

		>span:not(:first-child) {
			text-align: right;
			border-left: 1px solid #1416F4;
		}
	}

	.rangeHead {
		width: 100%;
		height: 60px;
		position: relative;
		display: flex;

		>img {
			position: absolute;
			top: 0;
			left: 0;
		}

		>span:nth-of-type(1) {
			border-right: 1px solid #1416F4;
			width: calc(50% + 1px);
		}

		>span {
			width: 50%;
			padding-top: 15px;
			font-size: 24px;
			font-weight: bold;
			color: #fff;
			text-align: center;
			box-sizing: border-box;
			position: relative;
			z-index: 2;
		}
	}

	.rangeHead+table {
		width: 100%;
		border-left: 1px solid #1416F4;
		box-sizing: border-box;

		td {
			width: 50%;
			height: 56px;
			border-right: 1px solid #1416F4;
			border-bottom: 1px solid #1416F4;
			font-size: 20px;
			text-align: center;
			color: #282828;
			box-sizing: border-box;
		}

	}

	.resourceResultList {
		.resourceHead {
			height: 80px;
			// padding: 0 10px;
			position: relative;
			display: flex;
			align-items: center;

			>img {
				position: absolute;
				top: 0;
				left: 0;
			}

			span {
				height: 60px;
				line-height: 60px;
				font-size: 22px;
				font-weight: bold;
				color: #fff;
				text-align: center;
				box-sizing: border-box;
				position: relative;
				z-index: 2;
			}

			span:not(:last-child) {
				border-right: 1px solid #1416F4;
			}

		}

		.resourceHead+table {
			width: 100%;
			border-left: 1px solid #1416F4;
			border-top: 1px solid #1416F4;
			box-sizing: border-box;
			margin-top: 10px;

			td {
				height: 56px;
				border-right: 1px solid #1416F4;
				border-bottom: 1px solid #1416F4;
				font-size: 20px;
				text-align: center;
				color: #282828;
				box-sizing: border-box;
				word-break: break-all;
				padding: 0 10px;
			}

			tr.summation {
				background: #F4F5FF;
				font-weight: bold;
			}

			tr.aggregate {
				background: #ECECFF;
				font-weight: bold;
			}

		}

	}

	.multiserviceLine {
		.amountPattern {
			text-align: right !important;
		}

		.resourceHead {
			span:nth-of-type(1) {
				width: 6%;
				padding-left: 10px;
			}

			span:nth-of-type(2) {
				width: 20%;
			}

			span:nth-of-type(3) {
				width: 22%;
			}

			span:nth-of-type(4) {
				width: 20%;
			}

			span:nth-of-type(5) {
				width: 29%;
			}
		}

		.resourceHead+table {
			td:nth-of-type(1) {
				width: 6%;
			}

			td:nth-of-type(2) {
				width: 20%;
			}

			td:nth-of-type(3) {
				width: 22%;
			}

			td:nth-of-type(4) {
				width: 20%;
			}

			td:nth-of-type(5) {
				width: 32%;
			}
		}
	}

	.singleLine {
		.resourceHead {
			span:nth-of-type(1) {
				padding-left: 10px;
			}

			span {
				width: 33.33%;
			}
		}

		.resourceHead+table {
			td {
				width: 33.33%;
			}

			td:not(:first-child) {
				text-align: right;
			}
		}
	}
</style>
