<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="newViewPage"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav :type="type" category='会计政策统计表' />
				</div>
				<div class="newViewInfo">
					<div class="fillinMain">
						<img class="leftTop" :src="require('@/assets/img/dataValutaion/leftTop.png')" />
						<img class="leftBottom" :src="require('@/assets/img/dataValutaion/leftBottom.png')" />
						<img class="rightTop" :src="require('@/assets/img/dataValutaion/rightTop.png')" />
						<img class="rightBottom" :src="require('@/assets/img/dataValutaion/rightBottom.png')" />
						<div class="fillinContent">
							<p class="examineTitle">会计政策统计表</p>
							<div>
								<div>
									<p class="title">企业所得税税率及加计扣除政策统计表</p>
									<ul class="forms tax">
										<i></i>
										<li>
											<div>税种</div>
											<div>适用税率%</div>
										</li>
										<li>
											<div>所得税<span class="reRed">*</span></div>
											<div v-for="(item, i) in taxRevenue">
												<input type="number" v-model="taxRevenue[i]">
												<span></span>
											</div>
										</li>
										<i></i>
									</ul>
									<ul class="forms tax" style="margin-top: 30px;">
										<i></i>
										<li>
											<div>项目</div>
											<div>加计扣除比例%</div>
										</li>
										<li>
											<div>研发费用<span class="reRed">*</span></div>
											<div>
												<input class="RDCost" type="number" ref="forbid" v-model="researchDeduction" />
												<span></span>
											</div>
										</li>
										<i></i>
									</ul>
								</div>

								<div>
									<p class="title">折旧摊销政策统计表（选填）</p>
									<p class="unit">单位：元</p>
									<ul class="forms accountant">
										<li>
											<div>项目</div>
											<div style="padding: 0 5px;">基准日当期（{{yearList[0].replace('年', '') - 1}}年全年）折旧/摊销额</div>
											<div>折旧摊销年限（年）</div>
											<div>残值率（%）</div>
										</li>
										<li v-for="(operation,index) in amortiza.amortiza">
											<div v-for="(item, i) in operation">
												<span v-if="i==0">{{operation[i]}}</span>
												<input v-else-if="i==2" type="number" ref="forbid" v-model="operation[i]" />
												<input v-else type="number" v-model="operation[i]" />

												<span class="showInput" v-if="i==0" style="display: none;">
													{{amortiza.amortiza[index][i]}}
												</span>
												<span class="showInput" v-else>
													{{method.formateNum(amortiza.amortiza[index][i],2)}}
												</span>
											</div>
										</li>
										<li class="majorTotal">
											<div v-for="(item, i) in amortiza.payAmount">
												<span v-if="i==0">合计</span>
												<input type="number" value="-" v-if="i==2||i==3" disabled>
												<input type="number" v-else-if="i==1" v-model="amortiza.payAmount[i]" />
												<span class="showInput" v-if="i==2||i==3">-</span>
												<span v-else-if="i==1" class="showInput">
													{{method.formateNum(amortiza.payAmount[i],2)}}
												</span>
											</div>
										</li>
									</ul>
								</div>

								<div>
									<p class="title">未来资本性支出预测表（选填）</p>
									<p class="unit">单位：元</p>
									<ul class="forms depreciation">
										<li>
											<div style="width: 25%;">项目</div>
											<div class="expenditureYears" style="width: 75%;">
												<p>
													<span>预测期第一期</span>
													<span>预测期第二期</span>
													<span>预测期第三期</span>
												</p>
												<p>
													<span v-for="(item, index) in yearList">{{item.replace('年', 'E')}}</span>
												</p>
											</div>
										</li>
										<li v-for="(operation,index) in expenditure.expenditure">
											<div v-for="(item, i) in operation">
												<span v-if="i==0">{{operation[i]}}</span>
												<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]" />
												<span class="showInput" v-if="i!=0">
													{{method.formateNum(expenditure.expenditure[index][i],2)}}
												</span>
											</div>
										</li>
									</ul>
								</div>

								<!-- <div>
									<p class="title">无形资产账面净值统计表（选填）</p>
									<p class="unit">单位：元</p>
									<ul class="forms bookAmountIntangibleAssets">
										<i></i>
										<li>
											<div>项目</div>
											<div>账面净值
												<i style="font-size: 14px;position: relative;top: -2px;">
													（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）
												</i>
											</div>
										</li>
										<li>
											<div>土地使用权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.land" />
												<span class="showInput">
													{{iaBookValue.land!=''?this.method.formateNum(iaBookValue.land,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>矿业权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.mine" />
												<span class="showInput">
													{{iaBookValue.mine?this.method.formateNum(iaBookValue.mine,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>水域使用权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.waters" />
												<span class="showInput">
													{{iaBookValue.waters?this.method.formateNum(iaBookValue.waters,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>外购软件使用权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.outsourcedSoftware" />
												<span class="showInput">
													{{iaBookValue.outsourcedSoftware?this.method.formateNum(iaBookValue.outsourcedSoftware,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>专利权（如发明专利、实用新型专利、外观设计专利）</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.patent" />
												<span class="showInput">
													{{iaBookValue.patent?this.method.formateNum(iaBookValue.patent,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>专有技术</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.knowHow" />
												<span class="showInput">
													{{iaBookValue.knowHow?this.method.formateNum(iaBookValue.knowHow,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>著作权（如计算机软件著作权、作品著作权等）</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.copyright" />
												<span class="showInput">
													{{iaBookValue.copyright?this.method.formateNum(iaBookValue.copyright,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>商标权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.trademarkRight" />
												<span class="showInput">
													{{iaBookValue.trademarkRight?this.method.formateNum(iaBookValue.trademarkRight,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>特许经营权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.concession" />
												<span class="showInput">
													{{iaBookValue.concession?this.method.formateNum(iaBookValue.concession,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>其他（如销售网络、客户关系、合同权益、域名等）</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.other" />
												<span class="showInput">
													{{iaBookValue.other?this.method.formateNum(iaBookValue.other,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>总计</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.total" disabled />
												<span class="showInput">{{this.method.formateNum(iaBookValue.total,2)}}</span>
											</div>
										</li>
										<i></i>
									</ul>
								</div> -->



							</div>

						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '@/common/fillin/examineTitle.vue'
	import examineNav from '../../nav/examineNav.vue'
	import common from '@/views/common'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [common],
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				type: '13',
				expenditure: {
					expenditure: [],
					payAmount: ['sum', '', '', '']
				},
				amortiza: {
					amortiza: [],
					payAmount: ['sum', '', '', '']
				},
				taxRevenue: [''],
				researchDeduction: '0', //研发费用比例
				deduction: false, //研发费用 true 适用   false  不适用(研发费用为0)
				reportId: '',
				amortizationYear: 'xxxx年xx月xx日', //折旧摊销政策统计表年份
				cacheAlert: false,
				isCacheValue: '0',
				yearList: ['', '', ''],
				bookAmountIntangibleAssets: {
					dataResource: ''
				},
				iaBookValue: {
					id: '',
					valuationReportId: '',
					land: '', //土地使用权
					mine: '', //矿业权
					waters: '', //水域使用权
					outsourcedSoftware: '', //外购软件使用权
					dataResources: '', //数据资源
					patent: '', //专利权
					knowHow: '', //专有技术
					copyright: '', //著作权
					trademarkRight: '', //商标权
					concession: '', //特许经营权
					other: '', //其他
					total: '' //总计
				},
				createTime: ''

			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialFutureValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();
						this.createTime = res.data.content.createTime

						var expenditureProduct = ["房屋建筑物", "房屋构筑物", "车辆", "机器设备", "办公家具", "电子设备", "住宅用地",
							"商业用地", "工业用地", "其他无形资产", "长期待摊费用"
						];

						//折旧摊销政策统计表年限
						this.yearList = res.data.content.years;
						this.amortizationYear = (res.data.content.years[0].replace('年', '') - 1) +
							'年12月31日';

						// 研发费用
						if (res.data.content.accStatisticsInfo) {
							this.researchDeduction = Math.round(res.data.content.accStatisticsInfo.researchDeduction * 100)

							// 未来资本性支出预测表
							if (res.data.content.accStatisticsInfo.expenditure) {
								this.expenditure = JSON.parse(res.data.content.accStatisticsInfo.expenditure);
							}

							// 折旧摊销政策统计表
							if (res.data.content.accStatisticsInfo.amortiza) {
								this.amortiza = JSON.parse(res.data.content.accStatisticsInfo.amortiza)
							}

							// 所得税
							if (res.data.content.accStatisticsInfo.taxRevenue) {
								this.taxRevenue = JSON.parse(res.data.content.accStatisticsInfo.taxRevenue);
							}

						} else {
							this.expenditure.expenditure = [];
							this.expenditure.expenditure.push([])
							for (var j = 0; j < 4; j++) {
								if (j == 0) {
									this.expenditure.expenditure[0].push("数据资源更新、改造支出")
								} else {
									this.expenditure.expenditure[0].push("")
								}
							}


							this.amortiza.amortiza = [];
							for (var i = 0; i < 11; i++) {
								this.amortiza.amortiza.push([])
								for (var j = 0; j < 4; j++) {
									if (j == 0) {
										this.amortiza.amortiza[i].push(expenditureProduct[i])
									} else {
										this.amortiza.amortiza[i].push("")
									}
								}
							}


						}


						// 无形资产账面金额统计表
						if (res.data.content.iaBookValue) {
							this.iaBookValue = res.data.content.iaBookValue
						}

					})
			}

		}
	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";

	input {
		pointer-events: none;
	}

	.title {
		font-weight: bold;
		font-size: 16px;
		color: #FFFFFF;
		margin: 35px auto 20px;
		text-align: center;
	}

	.tax {
		li {
			>div:first-child {
				width: 46%;
			}

			>div:last-child {
				width: 54%;
			}
		}
	}

	.accountant,
	.depreciation {
		li {
			>div {
				width: 25%;
			}

			>div:first-child {
				span {
					width: 70%;
					text-align: left;
				}
			}

		}
	}

	.depreciation {

		li:nth-of-type(1) {
			>div:first-child::after {
				height: 80px;
			}
		}

		li {
			>div:first-child {
				span {
					width: auto;
				}
			}
		}

		.expenditureYears {
			p:first-child {
				border-bottom: 1px solid #29FFFC;
				padding-bottom: 10px;

				span {
					font-weight: bold;
				}
			}

			p:last-child {
				padding-top: 10px;
			}

			P {
				span {
					width: 33%;
					position: relative;
				}

				>span:not(:last-child)::after {
					content: '';
					display: block;
					width: 2px;
					height: 25px;
					background: #29FFFC;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	.bookAmountIntangibleAssets {
		li {
			>div:first-child {
				width: 42%;
			}

			>div:last-child {
				width: 58%;
			}
		}
	}


	ul {

		li:not(:nth-of-type(1)) {
			border-top: 1px solid #29FFFC;
		}

		li {
			margin: 0 auto;
			padding: 16px 0;
			text-align: center;
			position: relative;
			display: flex;
			align-items: center;



			input {
				width: 80%;
				max-width: 200px;
				height: 30px;
				padding-right: 6px;
				font-size: 14px;
				background: #1237c6;
				color: #fff;
				text-align: right;
				border: 1px solid #29FFFC;
				margin: 0 auto;
			}

			input:disabled {
				background: #4B9BFF;
				border-color: #4B9BFF;
			}

			input:disabled+.showInput {
				display: block;
				background: #4B9BFF;
				border-color: #4B9BFF;
			}

			>div:not(:last-child)::after {
				content: '';
				display: block;
				width: 2px;
				height: 25px;
				background: #29FFFC;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			>div:first-child {
				font-size: 14px;
				font-weight: bold;
				color: #fff;
				padding: 0 10px;
			}

			>div:last-child {
				border-right: none;
			}

			>div {
				color: #fff;
				position: relative;

				.showInput {
					width: 78%;
					max-width: 198px;
					height: 27px;
					background: #1237c6;
					line-height: 28px;
					font-size: 14px;
					padding: 0 6px;
					color: #fff;
					text-align: right;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					overflow: hidden;
				}
			}
		}

		li:nth-of-type(1) {
			padding: 20px 0;


			div {
				font-size: 16px;
				font-weight: bold;
			}

		}


		li:not(:nth-of-type(1))::before {
			content: '';
			display: block;
			width: 10px;
			height: 9px;
			background: url(../../../../assets/img/contribution/cornerMark.png) center center no-repeat;
			background-size: contain;
			position: absolute;
			top: -1px;
			left: 0;
		}

	}
</style>